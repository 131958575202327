import React, { useEffect, useState, Component } from "react"
import MetaTags from 'react-meta-tags';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  page
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"
import { Link } from "react-router-dom";

import { getWallets as onGetWallets, getWalletsStatusCount } from "store/actions";

//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames";

const status = { 
  DRAFT: "Draft",
  IN_SIGNATURE: "In signature",
  IN_VALIDATION: "In validation"
 }
//NewCustomerModal
import NewCustomerModal from "components/Common/NewCustomerModal";

const ProjectsList = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [activeTab, setactiveTab] = useState("");
  const [query,setQuery] = useState(''); 

  function queryParams(status, search) {
    return {
      status: status,
      search: search
    }
  }
  // filter
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
      dispatch(onGetWallets(queryParams(tab, query)))
    }
  }
  // search
  function handleInputChange(e){
    const searchTargetValue = e.target.value;
    setQuery(searchTargetValue);
    dispatch(onGetWallets(queryParams(activeTab, searchTargetValue)))
  }

  function onPageChange(e){
    dispatch(onGetWallets(queryParams("", "")))
  }
  
  const { wallets, walletsCount } = useSelector((state) => ({
      wallets: state.wallets.wallets,
      walletsCount: state.wallets.walletsStatusCount
    }))

  const [everything, setEverything] = useState(wallets.length);

  useEffect(() => {
    dispatch(getWalletsStatusCount({ status: status.DRAFT }))
    dispatch(getWalletsStatusCount({ status: status.IN_SIGNATURE }))
    dispatch(getWalletsStatusCount({ status: status.IN_VALIDATION }))
    dispatch(onGetWallets({}))
    const data = localStorage.getItem("everything");
    if (data !== null) setEverything(data);
  }, []);
  
  // everything counter
  useEffect(() => {
    localStorage.setItem("everything", everything)  
  }, [everything])

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: wallets.length, // replace later with size(wallets),
    custom: true,
    page,
  }


  const ProjetColumns = [
    {
      dataField: "name",
      text: t("Customer"),
      sort: true,
      formatter: function formatter(cellContent, row) {
        // return <Link to={`/customer/${row.id}`}>{cellContent}</Link>
        return <Link to="/customer/1">{cellContent}</Link>
      }
    },
    {
      dataField: "creationDate",
      text: t("CreationDate"),
      sort: true
    },
    {
      dataField: "state",
      text: t("State"),
      sort: true,
      formatter: function formatter(cellContent, row) {
        switch (cellContent) {
          case 'Draft': return <p className="d-inline rounded-pill bg-dark text-white">{t("State." + cellContent)}</p>;break;
          case 'In signature': return <p className=" d-inline rounded-pill bg-primary text-white">{t("State." + cellContent)}</p>;break;
          case 'In validation': return <p className="d-inline rounded-pill bg-warning text-white">{t("State." + cellContent)}</p>;break;
        }
      }
    },
    {
      dataField: "upfrontPay",
      text: t("UpfrontPay"),
      sort: true
    },
    {
      dataField: "scheduledPay",
      text: t("ScheduledPay"),
      sort: true,
      formatter: function formatter(cellContent, row){
        if (row.mouthly) return (cellContent + "/" +(t("Month")));
        else return (cellContent + "/" +(t("Week")));
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: t("Action"),
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
        >
          {t("Details")}
        </Button>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('Project.allProjects')} | Hesiode</title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2 d-flex justify-content-between align-items-center">
              <h4>{t('Project.allProjects')}</h4>
          </div>
          
          <Row>
            <Col md="12" xl="12" xxl="12">
              <Card>
                <CardBody>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <div className="h4 card-title">{t('Project.allProjects')}</div>
                    <div>
                      <Link to="/projects/new" className="btn btn-primary">
                        {t('Project.addNewProject')}
                      </Link>
                    </div>
                  </div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "",
                        })}
                        onClick={() => {
                          toggle("");
                        }}
                      >
                        {t("State.Everything")}
                        <span className="badge rounded-pill bg-info my-0 mx-1">{ everything }</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "Draft",
                        })}
                        onClick={() => {
                          toggle("Draft");
                        }}
                      >
                        {t("State.Draft")}
                        <span className="badge rounded-pill bg-dark my-0 mx-1">{ walletsCount.Draft }</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "In validation",
                        })}
                        onClick={() => {
                          toggle("In validation");
                        }}
                      >
                        {t("State.In validation")}
                        <span className="badge rounded-pill bg-warning my-0 mx-1">{ walletsCount['In validation'] }</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "In signature",
                        })}
                        onClick={() => {
                          toggle("In signature");
                        }}
                      >
                        {t("State.In signature")}
                        <span className="badge rounded-pill bg-primary my-0 mx-1">{ walletsCount['In signature'] }</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <form className="app-search d-none d-lg-block w-25">
                    <div className="position-relative">
                      <input
                                type="text"
                                id="search"
                                name="search"
                                onChange={handleInputChange}
                                value={query}
                                className="form-control border border-primary border-opacity-25"
                                placeholder={t("Search") + "..."}
                        />
                        <span className="bx bx-search-alt" />
                      </div>
                    </form>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProjetColumns}
                    data={wallets}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={wallets}
                        columns={ProjetColumns}
                        bootstrap4
                        search
                      >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pagination pagination-rounded justify-content-end">
                            <PaginationListStandalone {...paginationProps} onPageChange={onPageChange}/>
                          </div>
                        </React.Fragment>
                      )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsList;
