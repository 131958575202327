import React from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {resourceShape} from "../../../components/Crud/shapes";
import {useDispatch, useSelector} from "react-redux";
import {selectCartHasProduct} from "../../../store/cart/selectors";
import {cartAddProduct} from "../../../store/cart/actions";
import {Sparklines, SparklinesLine, SparklinesReferenceLine} from "react-sparklines";
import bitcoinLogo from "../../../assets/images/crypto/bitcoin.png"
import ethereumLogo from "../../../assets/images/crypto/ethereum.png"
import polygonLogo from "../../../assets/images/crypto/polygon.png"

function generateFeed() {
    const startPoint = parseInt(Math.random() * 1000)
    let previousPoint = null

    return [startPoint, ...[...new Array(29)].map(() => {
        let diff = parseInt(Math.random() * 100)

        if (0 === (diff % 2)) {
            diff = -diff
        }

        return previousPoint = (previousPoint ?? startPoint) + diff
    })]
}

function Item(props) {
    const cartHasItem = useSelector(selectCartHasProduct(props.item))
    const dispatch = useDispatch()

    const graphData = generateFeed();
    const graphDataAverage = graphData.reduce((acc, item) => acc + item, 0) / graphData.length
    const graphStrokeColor = graphData[graphData.length - 1] < graphDataAverage ?
        "#ff7474" :
        "#5ed52e"

    return (
        <Card>
            <CardHeader style={{backgroundColor: '#3d008f', color: 'white'}} className={"pb-4 pt-3"}>
                <h4 style={{color: 'white'}}>{props.item.name}</h4>
                <div style={{textTransform: 'uppercase'}}>{props.t('Product.Marketplace.Item.Types.' + props.item.type)}</div>
            </CardHeader>
            <CardBody>
                <div className={"mb-3"} style={{fontSize: '.85rem'}}>{props.item.description}</div>
                <Row style={{fontSize: '1.05rem'}} className={"text-center"}>
                    <Col xs={4}>
                        <b>VL: </b> 420€
                    </Col>
                    <Col xs={4}>
                        <i className={"mdi mdi-account"} /> : 69
                    </Col>
                    <Col xs={4}>
                        <i className={"mdi mdi-treasure-chest"} /> : 420M
                    </Col>
                </Row>
                <Row className={"text-center"}>
                    <Col xs={4}>
                        <span style={{color: "green"}}>12% <i className="mdi mdi-arrow-up" /> </span>
                        <span style={{color: "gray"}}>24H </span>
                    </Col>
                    <Col xs={4}>
                        <span style={{color: "red"}}>-3% <i className="mdi mdi-arrow-down" /> </span>
                        <span style={{color: "gray"}}>1W </span>
                    </Col>
                    <Col xs={4}>
                        <span style={{color: "green"}}>5% <i className="mdi mdi-arrow-up" /> </span>
                        <span style={{color: "gray"}}>1M </span>
                    </Col>
                </Row>

                <div className={"my-4"}>
                    <Sparklines
                        height={75}
                        data={graphData}
                    >
                        <SparklinesLine
                            style={{
                                strokeWidth: 0.8,
                                stroke: graphStrokeColor,
                                fill: "transparent",
                            }}
                        />
                        <SparklinesReferenceLine type="mean" style={{stroke: graphStrokeColor, strokeDasharray: '5px 5px'}} />
                    </Sparklines>
                </div>

                <h4 className={"mb-3"}>{props.t('Product.Marketplace.Item.MainAssets')}:</h4>

                <Row className={"mb-3"}>
                    <Col xs={6} className={"py-2 border-bottom"}>
                        <img style={{width: '25px', marginRight: '10px'}} src={bitcoinLogo} alt={"bitcoin"} />
                        <span>Bitcoin (50%)</span>
                    </Col>
                    <Col xs={6} className={"py-2 border-bottom"}>
                        <img style={{width: '25px', marginRight: '10px'}} src={ethereumLogo} alt={"ethereum"} />
                        <span>Ethereum (30%)</span>
                    </Col>
                    <Col xs={6} className={"py-2 border-bottom"}>
                        <img style={{width: '25px', marginRight: '10px'}} src={polygonLogo} alt={"polygon"} />
                        <span>Polygon (20%)</span>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Button color="primary" size="small">
                            {props.t('Details')}
                            <i className="mdi mdi-eye" style={{marginLeft: '.5rem'}} />
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button color="success" size="small" style={{float: "right"}} onClick={() => dispatch(cartAddProduct(props.item))} disabled={Boolean(cartHasItem)}>
                            {props.t('Add')}
                            <i className="mdi mdi-cart" style={{marginLeft: '.5rem'}} />
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

Item.propTypes = {
    item: PropTypes.object,
    t: PropTypes.func,
    resource: resourceShape.isRequired,
}

export default withTranslation()(Item)
