import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {newPaymentMethodFormShape} from "../shapes";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

function Iban(props) {
    return (
        <FormGroup>
            <Label className="form-label fw-bold mt-2">{t("PaymentMethod.iban")}</Label>
            <Input
                name="iban"
                className="form-control"
                placeholder={props.t("PaymentMethod.enterIban")}
                type="text"
                onChange={props.form.handleChange}
                onBlur={props.form.handleBlur}
                value={props.form.values.iban || ""}
                invalid={
                    Boolean(props.form.touched.iban && props.form.errors.iban)
                }
            />
            {props.form.touched.iban && props.form.errors.iban ? (
                <FormFeedback type="invalid">{props.form.errors.iban}</FormFeedback>
            ) : null}
        </FormGroup>
    )
}

Iban.propTypes = {
    t: PropTypes.func.isRequired,
    form: newPaymentMethodFormShape.isRequired,
}

export default withTranslation()(Iban)
