import axios from "axios"

//redux
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {apiError} from "../store/auth/login/actions";

//apply base url for axios
export let API_URL = ""
if (process?.env?.REACT_APP_API_URL) {
    API_URL = process?.env?.REACT_APP_API_URL
} else if (process?.env?.REACT_APP_DEFAULTAUTH === "api") {
  API_URL = "https://prometheus.preprod.controlaltsoft.com"
}
const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => {
    const store = require('../store').default

    if (error.response.status === 401) {
        const expired = error.response.data?.message === 'JWT Token expired';
        store.dispatch(apiError('ApiError.tokenExpired', true, expired))

        if (expired && window.location.pathname !== '/login') {
            window.location.href = '/login'
        }
    }
    return Promise.reject(error.response)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
