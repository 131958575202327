import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCrudDeletedItem,
    selectCrudDeleteItemError,
    selectCrudDeleteItemLoading
} from "../../../store/crud/selectors";
import {cancelDeleteItem, realDeleteItem} from "../../../store/crud/actions";

function DeleteModale(props) {
    const dispatch = useDispatch()
    const deleted = useSelector(selectCrudDeletedItem)
    const loading = useSelector(selectCrudDeleteItemLoading)
    const error = useSelector(selectCrudDeleteItemError)

    return (
        <Modal isOpen={Boolean(deleted)} onClosed={() => dispatch(cancelDeleteItem())}>
            <ModalHeader>
                {
                    props.title ?
                        (props.translatable === false ? props.title : props.t(props.title, deleted?.item)) :
                        props.t('Crud.DeleteModale.DeleteARow')
                }
            </ModalHeader>
            <ModalBody>
                {Boolean(error) && (
                    <Alert color="danger">{error.toString()}</Alert>
                )}

                {
                    props.body ?
                        (props.translatable === false ? props.body : props.t(props.body, deleted?.item)) :
                        props.t('Crud.DeleteModale.AreYouSure')
                }
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="default" onClick={() => dispatch(cancelDeleteItem())}>{props.t('Cancel')}</Button>
                <Button type="button" color="primary" onClick={() => dispatch(realDeleteItem(props.onDeleteSuccess))} disabled={loading}>
                    {loading ?
                        <Spinner animation="border" style={{width: '25px', height: '25px'}} /> :
                        props.t('Delete')
                    }
                </Button>
            </ModalFooter>
        </Modal>
    )
}

DeleteModale.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    translatable: PropTypes.bool,
    t: PropTypes.func,
    onDeleteSuccess: PropTypes.func,
}

export default withTranslation()(DeleteModale)
