import React, {useState} from "react"
import PropTypes from "prop-types"
import {resourceShape} from "../shapes"
import {withTranslation} from "react-i18next"
import {Pagination as PaginationWrapper, PaginationItem} from "reactstrap"
import {useSelector} from "react-redux";
import {selectCrudPage, selectCrudPageLoading} from "../../../store/crud/selectors";

function Pagination(props) {
    const pageLoading = useSelector(selectCrudPageLoading(props.resource.name))
    const page = useSelector(selectCrudPage(props.resource.name))

    if (pageLoading || !page?.items) {
        return null
    }

    const currentPage = 1 + parseInt(page.offset / page.limit)
    const padding = props.padding || 2
    const firstPageItem = Math.max(1, currentPage - padding)
    const lastItem = Math.min(page.offset + page.limit, page.total)
    const numberOfItems = 1 + Math.min(padding * 2, parseInt(page.total / page.limit))

    if (numberOfItems < 2) {
        // Do not display any pagination if the total number of items is not higher than the limit
        return null
    }

    const lastPage = 1 + parseInt(page.total / page.limit)

    function loadPage(newPage) {
        if (newPage !== currentPage) {
            props.resource.changeFilter('offset', Math.max(0, (newPage - 1) * page.limit))
            props.resource.loadPage()
        }
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            {props.t('Displaying items {{offset}} to {{limit}} of {{total}} total items', {
                offset: Math.min(page.offset + 1, lastItem),
                limit: lastItem,
                total: page.total || 0,
            })}
            <PaginationWrapper>
                <PaginationItem className={"btn btn-outline-primary rounded-circle border-0"} disabled={currentPage === 1} onClick={() => loadPage(1)}>
                    <i className="bx bx-chevrons-left" />
                </PaginationItem>
                <PaginationItem
                    className={"btn btn-outline-primary rounded-circle border-0"}
                    style={{marginLeft: '5px'}}
                    disabled={currentPage === 1}
                    onClick={() => loadPage(currentPage - 1)}
                >
                    <i className="bx bx-chevron-left" />
                </PaginationItem>
                {[...new Array(numberOfItems)].map((_, index) => (
                    <PaginationItem
                        className={"btn btn-outline-primary rounded-circle border-0"}
                        style={{minWidth: '35px', marginLeft: '5px'}}
                        key={index}
                        active={firstPageItem + index === currentPage}
                        onClick={() => loadPage(firstPageItem + index)}
                    >
                        {firstPageItem + index}
                    </PaginationItem>
                ))}
                <PaginationItem
                    className={"btn btn-outline-primary rounded-circle border-0"}
                    style={{marginLeft: '5px'}}
                    disabled={(currentPage + 1) > lastPage}
                    onClick={() => loadPage(currentPage + 1)}
                >
                    <i className="bx bx-chevron-right" />
                </PaginationItem>
                <PaginationItem
                    className={"btn btn-outline-primary rounded-circle border-0"}
                    style={{marginLeft: '5px'}}
                    disabled={(currentPage + 1) > lastPage}
                    onClick={() => loadPage(lastPage)}
                >
                    <i className="bx bx-chevrons-right" />
                </PaginationItem>
            </PaginationWrapper>
        </div>
    )
}

Pagination.propTypes = {
    t: PropTypes.func,
    resource: resourceShape,
    padding: PropTypes.number,
}

export default withTranslation()(Pagination)
