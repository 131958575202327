import {CART_ADD_PRODUCT, CART_REMOVE_PRODUCT, CART_RESET} from "./actionTypes";

export function cartAddProduct(product) {
    return {
        type: CART_ADD_PRODUCT,
        product,
    }
}

export function cartRemoveProduct(product) {
    return {
        type: CART_REMOVE_PRODUCT,
        product,
    }
}

export function cartReset() {
    return {
        type: CART_RESET,
    }
}
