import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import PropTypes, { number } from 'prop-types'
import { connect, useDispatch } from "react-redux"
import {
    Row,
    Col,
    Button,
    Form,
    Modal,
    Label,
    Input,
} from "reactstrap";

import { getCustomers } from "store/actions"
//i18n
import { useTranslation } from "react-i18next"

const SearchModal = props => {
    const { customers, onGetCustomers } = props
    const dispatch = useDispatch();

    useEffect(() => {
        onGetCustomers()
    }, [onGetCustomers])

    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [query, setQuery] = useState('');
    
    const listItems = customers.slice(0, 5).map((customer) =>(
        <a key={customer.id} href={"/customer/" + customer.id} className="list-group-item list-group-item-action">
            <div className="text-primary">
                {customer.name} {customer.firstName}
            </div>
            <div>{customer.email}</div>
            <div>{customer.phone}</div>
        </a>
        )
    );

    function handleInputChange(e){
        const searchTargetValue = e.target.value;
        setQuery(searchTargetValue);
        dispatch(getCustomers(searchTargetValue))
    }

    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
        removeBodyCss();
    }

    return (
        <React.Fragment>
            <input
                  type="text"
                  onClick={() => {
                    toggleModal();
                }}
                  className="form-control"
                  placeholder={t("Search") + "..."}
                />

            <Modal size="md"
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {t("Search")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <input
                            type="text"
                            id="search"
                            name="search"
                            onChange={handleInputChange}
                            value={query}
                            className="form-control"
                            placeholder={t("Search") + "..."}
                        />
                        <ul className="list-group mb-3">
                            {listItems}
                        </ul>
                    </Form>
                </div>
            </Modal>
        </React.Fragment>
    )
}
SearchModal.propTypes = {
    customers: PropTypes.any,
    onGetCustomers: PropTypes.func,
    t: PropTypes.any
}

const mapStateToProps = state => ({ ...state.customer })

const mapDispatchToProps = dispatch => ({
    onGetCustomers: () => dispatch(getCustomers()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchModal)