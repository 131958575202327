import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, GET_CURRENT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError, getCurrentProfileSuccess, getCurrentProfileError } from "./actions"

import {
  getFakeProfile,
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper"

function* showProfile() {
  try {
    if (process?.env?.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(getFakeProfile)
      yield put(getCurrentProfileSuccess(response))
    } else if (process?.env?.REACT_APP_DEFAULTAUTH === "api") {}
  } catch (error) {
    yield put(getCurrentProfileError(error))
  }
}

function* editProfile({ payload: { user } }) {
  try {
    if (process?.env?.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        password: user.password,
        phone: user.phone,
        companyName: user.companyName,
        companyAddress: user.companyAddress,
        companyZipCode: user.companyZipCode,
        companyCity: user.companyCity,
        companyEmail: user.companyEmail,
        companyPhone: user.companyPhone,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process?.env?.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        password: user.password,
        phone: user.phone,
        companyName: user.companyName,
        companyAddress: user.companyAddress,
        companyZipCode: user.companyZipCode,
        companyCity: user.companyCity,
        companyEmail: user.companyEmail,
        companyPhone: user.companyPhone,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_CURRENT_PROFILE, showProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
