import React from "react"
import PropTypes from "prop-types"
import {resourceShape} from "../../../components/Crud/shapes";
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import SummaryTable from "./SummaryTable";
import ProductsTable from "./ProductsTable";
import {useSelector} from "react-redux";
import {selectCartHasAnyProduct} from "../../../store/cart/selectors";

function Cart(props) {
    const hasProduct = useSelector(selectCartHasAnyProduct)

    return (
        <Card>
            <CardBody>
                <SummaryTable />
                <ProductsTable />

                <div className={"mt-3"} style={{display: 'flex', justifyContent: 'center'}}>
                    <Button color="dark" style={{marginRight: '10px'}}>
                        <i className="mdi mdi-arrow-left-circle" style={{marginRight: '.5rem'}} />
                        {props.t('Product.Marketplace.Cart.EditProject')}
                    </Button>
                    <Button color="success" disabled={!hasProduct}>
                        {props.t('Product.Marketplace.Cart.Validate')}
                        <i className="mdi mdi-arrow-right-circle" style={{marginLeft: '.5rem'}} />
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}

Cart.propTypes = {
    resource: resourceShape.isRequired,
    t: PropTypes.func,
}

export default withTranslation()(Cart)
