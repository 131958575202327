import {
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESSFUL,
  ACTIVATE_USER_FAILED,
} from "./actionTypes"

export const activateUser = (token, user, password, navigate) => {
  return {
    type: ACTIVATE_USER,
    payload: { token, user, password, navigate},
  }
}

export const activateUserSuccessful = () => {
  return {
    type: ACTIVATE_USER_SUCCESSFUL,
    payload: { },
  }
}

export const activateUserFailed = user => {
  return {
    type: ACTIVATE_USER_FAILED,
    payload: user,
  }
}
