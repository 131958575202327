import React from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
} from "reactstrap";

//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { registerUser } from "store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";

const NewUser = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
  
    const validation = useFormik({
        initialValues: {
          username: '',
          password: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          roles: '',
          birthdate: ''
        },
        validationSchema: Yup.object({
            username: Yup.string().required(t("Customer.pleaseEnterUsername")),
            password: Yup.string().required(t("Customer.pleaseEnterPassword")),
            first_name: Yup.string().required(t("Customer.pleaseEnterFirstname")),
            last_name: Yup.string().required(t("Customer.pleaseEnterName")),
            email: Yup.string().required(t("Customer.pleaseEnterMail")),
            password: Yup.string().required(t("Customer.pleaseEnterPassword")),
        }),
        onSubmit: (values) => {
          dispatch(registerUser(values));
        }
    })

    const { registrationError, loading } = useSelector(state => ({
        registrationError: state.Account.registrationError,
        loading: state.Account.loading,
    }));

    function showErrorMessage(error) {
        if (!error || !error.statusText) {
            return;
        }
        const errors = error.data.errors
        const errorsMessages = errors.map(error => error.path + " " + t(error.code))
        
        return (
            <Alert color="danger">
                {t(registrationError.statusText.toString())}
                {
                    errorsMessages.map((error) => <div key={error}>- {error}</div>)
                }
            </Alert>
        )
    }

    return (
        <React.Fragment>
            <MetaTags>
            <title>Login | Hesiode</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                    <Card className="overflow-hidden">
                        <div className="bg-primary bg-soft">
                            <Row>
                                <Col xs={7}>
                                <div className="text-primary p-4">
                                    <h5 className="text-primary">{t('login.welcome')}</h5>
                                    <p>{t('login.whyRegister')}</p>
                                </div>
                                </Col>
                                <Col className="col-5 align-self-end">
                                <img src={profile} alt="" className="img-fluid" />
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="pt-0">
                        <div>
                            <Link to="/" className="auth-logo-light">
                            <div className="avatar-md profile-user-wid mb-4">
                                <span className="avatar-title rounded-circle bg-light">
                                <img
                                    src={logo}
                                    alt=""
                                    className="rounded-circle"
                                    height="34"
                                />
                                </span>
                            </div>
                            </Link>
                        </div>
                        <div className="p-2">
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                            {showErrorMessage(registrationError)}

                            <div className="mb-3">
                                <Label className="form-label">{t('Customer.firstname')}</Label>
                                <Input
                                    name="first_name"
                                    className="form-control"
                                    placeholder={t('Customer.enterFirstname')}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_name || ""}
                                    invalid={
                                        validation.touched.first_name && validation.errors.first_name ? true : false
                                    }
                                />
                                {validation.touched.first_name && validation.errors.first_name ? (
                                <FormFeedback type="invalid">{validation.errors.first_name  }</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{t('Customer.lastname')}</Label>
                                <Input
                                    name="last_name"
                                    className="form-control"
                                    placeholder={t('Customer.enterName')}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.last_name || ""}
                                    invalid={
                                        validation.touched.last_name && validation.errors.last_name ? true : false
                                    }
                                />
                                {validation.touched.last_name && validation.errors.last_name ? (
                                <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{t('Customer.username')}</Label>
                                <Input
                                    name="username"
                                    className="form-control"
                                    placeholder={t('Customer.enterUsername')}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    invalid={
                                        validation.touched.username && validation.errors.username ? true : false
                                    }
                                />
                                {validation.touched.username && validation.errors.username ? (
                                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{t('login.Password')}</Label>
                                <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type="password"
                                    placeholder={t('login.EnterPassword')}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.password && validation.errors.password ? true : false
                                    }
                                />
                                {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{t('Email')}</Label>
                                <Input
                                    name="email"
                                    className="form-control"
                                    placeholder={t('login.EnterEmailLabel')}
                                    type="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                        validation.touched.email && validation.errors.email ? true : false
                                    }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{t('Customer.phone')}</Label>
                                <Input
                                    name="phone"
                                    className="form-control"
                                    placeholder={t('Customer.enterPhone')}
                                    type="tel"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                    invalid={
                                        validation.touched.phone && validation.errors.phone ? true : false
                                    }
                                />
                                {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{t('Customer.birthday')}</Label>
                                <Input
                                    name="birthdate"
                                    className="form-control"
                                    placeholder={t('Customer.enterBirthday')}
                                    type="date"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.birthdate || ""}
                                    invalid={
                                        validation.touched.birthdate && validation.errors.birthdate ? true : false
                                    }
                                />
                                {validation.touched.birthdate && validation.errors.birthdate ? (
                                <FormFeedback type="invalid">{validation.errors.birthdate}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mt-3 d-grid">
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {t('Create')}
                                </Button>
                            </div>

                            <div className="mt-4 text-center">
                                <Link to="/login" className="text-muted">
                                    <i className="mdi mdi-lock me-1" />
                                    {t('login.GoToLogin')}
                                </Link>
                            </div>
                            </Form>
                        </div>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

NewUser.propTypes = {
  t: PropTypes.any
};

export default NewUser;