import React, {useEffect} from "react"
import ResourceDataTable from "../../components/Crud/ResourceDataTable";
import {resourceShape} from "../../components/Crud/shapes";
import moment from "moment-with-locales-es6"
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

function PaymentMethodsTable({resource, customer, ...props}) {
    const inited = useSelector(resource.selectPageInited())

    useEffect(() => {
        if (inited) {
            resource.changeFilter('user', [customer])
            resource.loadPage()
        }
    }, [customer, inited])

    return (
        <ResourceDataTable
            striped
            {...props}
            resource={resource}
            initFilters={{user: [customer], limit: 5}}
            selectedRow={props.selectedRow}
            columns={[
                {title: 'Type', sort: true, data: 'type'},
                {title: 'Currency', data: row => row.currency.symbol},
                {title: 'Identifier', data: row => {
                    switch (row.type) {
                        case 'sepa':
                            return row.iban
                        default:
                            return ''
                    }
                }},
                {title: 'Added at', sort: 'created_at', data: row => moment(row.created_at).format('LLLL')},
            ]}
        >
            {props.children}
        </ResourceDataTable>
    )
}

PaymentMethodsTable.propTypes = {
    resource: resourceShape.isRequired,
    customer: PropTypes.string.isRequired,
    rowClick: PropTypes.func,
    rowDoubleClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    selectedRow: PropTypes.any,
    children: PropTypes.any,
}

export default PaymentMethodsTable
