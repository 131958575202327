import { getCookie } from "helpers/cookies"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  TWO_AUTH_USER,
  TWO_AUTH_SUCCESS, USER_LOADED,
} from "./actionTypes"

const initialState = {
  error: "",
  translateError: false,
  loading: false,
  user: null,
  userLoading: false,
  accessToken: localStorage.getItem(process?.env?.REACT_APP_ACCESS_TOKEN_ITEM_NAME) ||
      getCookie(process?.env?.REACT_APP_ACCESS_TOKEN_ITEM_NAME) ||
      null,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        accessToken: action.payload.token,
        userLoading: true,
      }
      break
    case TWO_AUTH_USER:
        state = {
          ...state,
          loading: true,
        }
        break
    case TWO_AUTH_SUCCESS:
      state = {
        ...state,
        accessToken: action.payload.token,
        loading: false,
        userLoading: true,
      }
      break
    case USER_LOADED:
      state = { ...state, user: action.payload.user, userLoading: false }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        accessToken: action.payload.resetToken ? null : state.accessToken,
        loading: false,
        userLoading: false,
        translateError: action.payload.translate,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
