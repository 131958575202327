import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import {
    Row,
    Col,
    Button,
    Form,
    Modal,
    Label,
    Input,
} from "reactstrap";

//i18n
import { useTranslation } from "react-i18next"

const UpdateIbanModal = props => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
        removeBodyCss();
    }

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={() => {
                    toggleModal();
                }}
                data-toggle="modal"
                data-target="#myModal"
                outline={props.minimal}
            >
                <i className={"fas fa-pen" + (props.minimal ? "" : "me-2")}/>
                { props.minimal ? "" : <span>{t("Edit")}</span> }
            </Button>

            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {t("Customer.updateIban")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-name">{t("Customer.iban")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-name"
                                        placeholder={t("Customer.iban")}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            toggleModal();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        {t("Close")}
                    </button>
                    <button type="submit" className="btn btn-primary w-md">
                        {t("Update")}
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

UpdateIbanModal.propTypes = {
    minimal: PropTypes.any,
}

export default UpdateIbanModal