import React from "react"
import PropTypes from "prop-types"
import { Navigate, Outlet } from 'react-router-dom'
import {useSelector} from "react-redux";
import {selectAccessToken, selectConnectedUser, selectUserLoading} from "../store/auth/login/selector";

const PrivateRoutes = ({ isAuthProtected }) => {
    const user = useSelector(selectConnectedUser)
    const userLoading = useSelector(selectUserLoading)
    const needToConnect = isAuthProtected && !user

    if (userLoading) {
        // Add loading page
        return null
    }

    return (
        needToConnect ? <Navigate to='/login'/> : <Outlet/>
    )
}

PrivateRoutes.propTypes = {
    isAuthProtected: PropTypes.bool,
}

export default PrivateRoutes;
