import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {newPaymentMethodFormShape} from "../shapes";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

function Bic(props) {
    return (
        <FormGroup>
            <Label className="form-label fw-bold mt-2">{t("PaymentMethod.bic")}</Label>
            <Input
                name="bic"
                className="form-control"
                placeholder={props.t("PaymentMethod.enterBic")}
                type="text"
                onChange={props.form.handleChange}
                onBlur={props.form.handleBlur}
                value={props.form.values.bic || ""}
                invalid={
                    Boolean(props.form.touched.bic && props.form.errors.bic)
                }
            />
            {props.form.touched.bic && props.form.errors.bic ? (
                <FormFeedback type="invalid">{props.form.errors.bic}</FormFeedback>
            ) : null}
        </FormGroup>
    )
}

Bic.propTypes = {
    t: PropTypes.func.isRequired,
    form: newPaymentMethodFormShape.isRequired,
}

export default withTranslation()(Bic)
