import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import {connect, useDispatch, useSelector} from "react-redux"
import MetaTags from 'react-meta-tags';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table
} from "reactstrap"
import { useParams } from "react-router-dom"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"

import { getCustomerProfileAction } from "store/actions"

//i18n
import {useTranslation, withTranslation} from "react-i18next"
import UpdatePatrimonyModal from "components/Common/UpdatePatrimonyModal";
import UpdateCustomerPersonnalInformationModal from "components/Common/UpdateCustomerPersonnalInformationModal";
import UpdateEarningModal from "components/Common/UpdateEarningModal";
import UpdateIbanModal from "components/Common/UpdateIbanModal";
import {selectCurrentCustomer} from "../../store/customer/selectors";

const CustomerProfil = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const currentCustomer = useSelector(selectCurrentCustomer)
    const wallets = currentCustomer && currentCustomer.wallets ? [...currentCustomer.wallets] : []

    const { id: customerId } = useParams()

    useEffect(() => {
        const timeout = setTimeout(() => dispatch(getCustomerProfileAction(customerId)))

        return () => clearTimeout(timeout)
    }, [customerId])
    
    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: wallets.length, // replace later with size(wallets),
      custom: true,
    }

  const ProjetColumns = [
    {
      dataField: "balance",
      text: t("Outstanding"),
      sort: true
    },
    {
      dataField: "state",
      text: t("State"),
      sort: true,
      formatter: function formatter(cellContent, row) {
        return t("State." + cellContent)
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: t("Action"),
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
        >
          {t("Edit")}
        </Button>
      ),
    },
  ]

  const rowClassNameFormat = (row, rowIdx) => {
    return row.state === "Draft" ? 'bg-warning bg-gradient' : 'td-column-function-odd-example';
  }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                <title>{t('Profile')} | Hesiode</title>
                </MetaTags>
                <Container fluid>
                    <h4>{t('Profile')}</h4>

                    <Row>
                        <Col md="6" lg="12" xl="6" xxl="5">
                            <Card>
                                <CardBody>
                                    <div className="mb-4 d-flex justify-content-between align-items-center">
                                        <CardTitle>{t("Customer.personnalInformation")}</CardTitle>
                                        <UpdateCustomerPersonnalInformationModal />
                                    </div>
                                    <div className="text-center">
                                        <img
                                            src={avatar1}
                                            alt=""
                                            className="mx-auto avatar-md rounded-circle img-thumbnail"
                                        />
                                        {/* namen firstname */}
                                        <h5 className="text-center">{currentCustomer.first_name} {currentCustomer.last_name}</h5>
                                    </div>
                                    <p className="text-muted mb-2">
                                        {currentCustomer.comment}
                                    </p>
                                    <div className="table-responsive mb-3">
                                        <Table className="table-nowrap mb-0">
                                        <tbody>
                                            <tr>
                                                <th scope="row">{t("Customer.maritalStatus")}:</th>
                                                <td>{currentCustomer.maritalStatus}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{t("Customer.phone")}:</th>
                                                <td>{currentCustomer.phone}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{t("Email")}:</th>
                                                <td>{currentCustomer.email}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{t("Customer.address")}:</th>
                                                <td>{currentCustomer?.address?.lines?.map((line, index) => (
                                                    <span key={index}>{line} <br/></span>
                                                ))}
                                                {currentCustomer?.address?.zip_code}, {currentCustomer?.address?.city}
                                                {Boolean(currentCustomer?.address?.country || currentCustomer?.address?.state) && (
                                                    <>
                                                        <br/>
                                                        {currentCustomer?.address?.state ? currentCustomer?.address?.state + ',' : ''}
                                                        {currentCustomer?.address?.country}
                                                    </>
                                                )}
                                                </td>
                                            </tr>
                                        </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6" lg="12" xl="6" xxl="5">
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <span className="text-muted fw-medium">
                                                            {t("Customer.patrimony")}
                                                        </span>
                                                    </div>
                                                    <h4 className="mb-0">
                                                        {currentCustomer.patrimony}</h4>
                                                </div>
                                                <UpdatePatrimonyModal customer={currentCustomer.id} minimal className="mb-2" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <span className="text-muted fw-medium">
                                                            {t("Customer.earning")}
                                                        </span>
                                                    </div>
                                                    <h4 className="mb-0">{currentCustomer.earning}</h4>
                                                </div>
                                                <UpdateEarningModal
                                                    minimal
                                                    customer={currentCustomer.id}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <span className="text-muted fw-medium">
                                                            {t("Customer.iban")}
                                                        </span>
                                                    </div>
                                                    <h4 className="mb-0">{currentCustomer.iban}</h4>
                                                </div>
                                                <UpdateIbanModal minimal />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                        <Col md="6" lg="6" xl="6" xxl="5">
                            <Card>
                                <CardBody>
                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                        <div className="h4 card-title">{t('Wallets')}</div>
                                        <div>
                                        <a className="btn btn-primary" href={"/projects/new?customerId=" + currentCustomer.id}>{t('Wallet.addNewWallet')}</a>
                                        </div>
                                    </div>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={ProjetColumns}
                                        data={wallets}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            data={wallets}
                                            columns={ProjetColumns}
                                            bootstrap4
                                            search
                                        >
                                        {toolkitProps => (
                                            <React.Fragment>
                                            <Row>
                                                <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField="id"
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        classes={
                                                            "table align-middle table-nowrap table-check"
                                                        }
                                                        headerWrapperClasses={"table-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                        rowClasses={rowClassNameFormat}
                                                    />
                                                </div>
                                                </Col>
                                            </Row>
                                            <div className="pagination pagination-rounded justify-content-end">
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                            </React.Fragment>
                                        )}
                                        </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6" lg="6" xl="6" xxl="5">
                            <Card className="d-none"> {/* effacer la class pour réafficher */}
                                <CardBody>
                                    <CardTitle>{t("Customer.activities")}</CardTitle>

                                    <SimpleBar style={{ maxHeight: "250px" }}>
                                        <ul className="verti-timeline list-unstyled">
                                        {currentCustomer.events?.map((event, key) => (
                                            <li key={key} className="event-list">
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle font-size-18" />
                                                </div>
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <h5 className="font-size-14 text-nowrap">
                                                            {event.date}{" "}
                                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                        </h5>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>{event.desc}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                        </ul>
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

CustomerProfil.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(CustomerProfil)
