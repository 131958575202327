import {ADD_TOAST, REMOVE_TOAST} from "./actionTypes";

const initialState = {
    toasts: [],
}

export default function toast(state = initialState, action) {
    switch (action.type) {
        case ADD_TOAST:
            return {
                ...state,
                toasts: [...state.toasts, action.payload],
            }

        case REMOVE_TOAST:
            return  {
                ...state,
                toasts: state.toasts.filter(toast => toast !== action.payload),
            }

        default:
            return state
    }
}
