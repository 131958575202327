import { takeEvery, fork, all, delay, put } from "redux-saga/effects"

//Account Redux states
import {ADD_TOAST} from "./actionTypes"
import {hideToast, removeToast} from "./actions";

// Is user activate successful then direct plot user in redux.
function* toastTimeout({ payload: toast }) {
    const timeout = toast.timeout || 6000

    yield delay(timeout)
    yield put(removeToast(toast))
}

export function* watchToastTimeout() {
  yield takeEvery(ADD_TOAST, toastTimeout)
}

function* toastSaga() {
  yield all([fork(watchToastTimeout)])
}

export default toastSaga
