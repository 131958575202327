import { call, put, takeEvery, select } from "redux-saga/effects"

// Customer Redux States
import {
    GET_CUSTOMERS,
    ADD_NEW_INVESTOR,
    DELETE_CUSTOMER,
    UPDATE_CUSTOMER,
    GET_CUSTOMER_PROFILE,
} from "./actionTypes"
import {
    getCustomersFail,
    getCustomersSuccess,
    getCustomerProfileFail,
    getCustomerProfileSuccess,
    addCustomerFail,
    addCustomerSuccess,
    updateCustomerSuccess,
    updateCustomerFail,
    deleteCustomerSuccess,
    deleteCustomerFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
    getCustomerProfileApiCall,
    updateCustomer,
    deleteCustomer,
} from "helpers/fakebackend_helper"
import {
  createNewInvestor,
  getInvestors,
  getInvestor,
} from "helpers/fire_api_helper"
import { selectAccessToken } from "store/auth/login/selector"
import {filterObject, notEmpty} from "../../helpers/object_utils";

function* fetchCustomers({ search }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(getInvestors, search, accessToken)
    yield put(getCustomersSuccess(response.items))
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}

function* fetchCustomerProfile({ customerId }) {
    const accessToken = yield select(selectAccessToken)

    try {
    const response = yield call(getInvestor, customerId, accessToken);
    yield put(getCustomerProfileSuccess(response))
  } catch (error) {
      console.log(error)
    yield put(getCustomerProfileFail(error))
  }
}

function* onUpdateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer)
    yield put(updateCustomerSuccess(response))
  } catch (error) {
    yield put(updateCustomerFail(error))
  }
}

function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomer, customer)
    yield put(deleteCustomerSuccess(response))
  } catch (error) {
    yield put(deleteCustomerFail(error))
  }
}

function* onAddNewInvestor({ payload: investor }) {
  const accessToken = yield select(selectAccessToken)

  try {
    // the username is the email
    const newInvestor = {
      last_name: investor.name,
      first_name: investor.firstname,
      username: investor.email,
      email: investor.email,
      password: investor.email,
      birthdate: investor.birthday,
      phone: investor.phone,
      address: filterObject({
          lines: [investor.address].filter(notEmpty),
          zip_code: investor.zipcode,
          city: investor.city,
      }),
      comment: investor.comment,
      roles: [
        "ROLE_INVESTOR"
      ],
    }
    const response = yield call(createNewInvestor, newInvestor, accessToken)

    yield put(addCustomerSuccess(response))
  } catch (error) {
    yield put(addCustomerFail(error))
  }
}

function* customerSaga() {
    yield takeEvery(GET_CUSTOMERS, fetchCustomers)
    yield takeEvery(GET_CUSTOMER_PROFILE, fetchCustomerProfile)
    yield takeEvery(ADD_NEW_INVESTOR, onAddNewInvestor)
    yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer)
    yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer)
}

export default customerSaga
