import PropTypes from "prop-types"

export const newPaymentMethodFormShape = PropTypes.shape({
    values: PropTypes.shape({
        iban: PropTypes.string.isRequired,
        bic: PropTypes.string.isRequired,
    }),
    touched: PropTypes.shape({
        iban: PropTypes.bool,
        bic: PropTypes.bool,
    }),
    errors: PropTypes.shape({
        iban: PropTypes.any,
        bic: PropTypes.any,
    }),
    submitForm: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
})
