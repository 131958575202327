import {
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMER_PROFILE_SUCCESS,
    GET_CUSTOMER_PROFILE_FAIL,
    ADD_NEW_INVESTOR,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAIL,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAIL, RESET_ADD_NEW_INVESTOR,
} from "./actionTypes"

const INIT_STATE = {
    error: null,
    addedCustomer: null,
    loading: false,
    customers: [],
    currentCustomer: {
        events: []
    }
}

const Customer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload,
            }

        case GET_CUSTOMERS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_CUSTOMER_PROFILE_SUCCESS:
            return {
                ...state,
                currentCustomer: action.payload,
            }

        case GET_CUSTOMER_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case RESET_ADD_NEW_INVESTOR:
            return {
                ...state,
                loading: false,
                error: null,
                addedCustomer: null,
            }

        case ADD_NEW_INVESTOR:
            return {
                ...state,
                loading: true,
            }

        case ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                addedCustomer: action.payload,
                customers: [...state.customers, action.payload],
            }

        case ADD_CUSTOMER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: state.customers.map(currentCustomer =>
                    currentCustomer.id.toString() === action.payload.id.toString()
                    ? { currentCustomer, ...action.payload }
                    : currentCustomer
                ),
            }

        case UPDATE_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: state.customers.filter(
                    currentCustomer => currentCustomer.id.toString() !== action.payload.id.toString()
                ),
            }

        case DELETE_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
          return state
    }
}

export default Customer
