import React, { useEffect, useState, Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  Label,
  Input,
  Table
} from "reactstrap"
import { useParams } from "react-router-dom"

//i18n
import { useTranslation } from "react-i18next"

const Allocation = props => {
    const { t } = useTranslation()

    const [currentProject, setCurrentProject] = useState({
        name: "Horizon 2025 BTC-ETH",
        description: "",
        risk: "balanced",
        investmentDuration: "24 - 36 mois",
        amountInitialPayments: "15 000",
        amountProgrammedPayments: "250",
        paymentFrequency: "monthly",
    });

    const [currentInvestor, setCurrentInvestor] = useState({
        name: "Price",
        firstname: "Cynthia",
        phone: "06 00 65 25 67",
        email: "cynthiaskote@gmail.com",
        address: "9 rue JJ ROUSSEAU",
        zipcode: "21000",
        city: "DIJON",
        comment: "Mlle Price est avocate en région parisienne",
    });

    const [currentFiles, setCurrentFiles] = useState(
    [{
        document: "Fiche produit",
        fccName: "FCC BTC-ETH Natif",
        provider: "Hesiode",
        address: "0x95542315453412fgrf531232102545rg4df4gcfe",
        distribution: "50",
        approximateAmount: "125"
    },
    {
        document: "Fiche produit",
        fccName: "FCC Horizen",
        provider: "Akimoz",
        address: "0x95542315453412fgrf531232102545rg4df4gcfe",
        distribution: "50",
        approximateAmount: "125"
    }])

    function riskSwitch (param) {
        switch (param) {
            case 'careful': return <p className="text-success">{t("Wallet." + param)}</p>;break;
            case 'balanced': return <p className="text-warning">{t("Wallet." + param)}</p>;break;
            case 'dynamic': return <p className="text-danger">{t("Wallet." + param)}</p>;break;
          };
    }

  const ProjetColumns = [
    {
      dataField: "document",
      text: t("Document"),
      sort: true,
      formatter: (cellContent, row) => (
        <div>
            <i className="fa fa-solid fa-file-pdf me-2 text-danger"></i>{cellContent}
        </div>
      ),
    },
    {
      dataField: "fccName",
      text: t("FccName"),
      sort: true,
    },
    {
      dataField: "provider",
      text: t("Provider"),
      sort: true
    },
    {
      dataField: "address",
      text: t("Customer.address"),
      sort: true
    },
    {
      dataField: "distribution",
      text: t("Distribution")+"(%)",
      sort: true,
      formatter: (cellContent, row) => (
        <input type="email" className="form-control mx-auto" id="distribution" placeholder={cellContent+'%'} />
      ),
    },
    {
      dataField: "approximateAmount",
      text: t("ApproximateAmount")+"(€)",
      sort: true,
      formatter: (cellContent, row) => (
        <input type="email" className="form-control" id="approximateAmount" placeholder={cellContent+'€'} />
      ),
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: t("Action"),
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="d-flex justify-content-around">
        <i className="mdi mdi-eye text-primary"></i>
        <i className="bx bxs-trash text-danger"></i>
        </div>
      ),
    },
  ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                <title>{t('Allocation')} | Hesiode</title>
                </MetaTags>
                <Container fluid>
                    <h4 className="mb-3">{t('Allocation')}</h4>
                    <Row>
                        <Col xl="8">
                            <Card className="card h-100">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="p-2 flex-shrink-1">
                                            <div className="h4 card-title mb-3">{t('Project Summary')} <i className="mdi mdi-pencil text-success"></i></div>
                                            <div>
                                                {t('Project Summary Presentation')}
                                            </div>
                                            <div className="table-responsive mt-3">
                                                <table className="table table-wrap table">
                                                    <tbody>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Project Name')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.name}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.riskProfil')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{riskSwitch(currentProject.risk)}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.investmentDuration')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.investmentDuration}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.amountInitialPayments')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.amountInitialPayments}{"€"}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.amountProgrammedPayments')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.amountProgrammedPayments}{"€/"}{currentProject.paymentFrequency}</div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card className="card h-100">
                                <CardBody>
                                    <div className="">
                                        <div className="h4 card-title mb-3">{t('Investor File')} <i className="mdi mdi-pencil text-success"></i></div>
                                        <p>{currentInvestor.comment}</p>
                                        <div className="table-responsive mb-5">
                                            <table className="table table-nowrap table">
                                                <tbody>
                                                    <tr>
                                                        <td><div className="fw-bold my-1">{t('Investor')} {" :"}</div></td>
                                                        <td><div className="my-1 mx-5">{currentInvestor.firstname}{" "}{currentInvestor.name}</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div className="fw-bold my-1">{t('Customer.phone')} {" :"}</div></td>
                                                        <td><div className="my-1 mx-5">{currentInvestor.phone}</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div className="fw-bold my-1">{t('Customer.mail')} {" :"}</div></td>
                                                        <td><div className="my-1 mx-5">{currentInvestor.email}</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div className="fw-bold my-1">{t('Customer.address')} {" :"}</div></td>
                                                        <td><div className="my-1 mx-5">{currentInvestor.address}{", "}{currentInvestor.zipcode}{" "}{currentInvestor.city}</div></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="mb-3">{t('Allocation')} : {t('Project')}{" "}{currentInvestor.firstname}{" "}{currentInvestor.name}{" - "}{currentProject.name}</h4>
                                    <ToolkitProvider
                                        keyField="id"
                                        data={currentFiles}
                                        columns={ProjetColumns}
                                        bootstrap4
                                        search
                                    >
                                    {toolkitProps => (
                                        <React.Fragment>
                                        <Row>
                                            <Col xl="12">
                                            <div className="table-responsive mx-4">
                                                <BootstrapTable
                                                keyField="id"
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                classes={
                                                    "table align-middle table-nowrap table-check"
                                                }
                                                headerWrapperClasses={"table-light"}
                                                {...toolkitProps.baseProps}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        </React.Fragment>
                                    )}
                                    </ToolkitProvider>
                                    <div className="d-flex justify-content-end">
                                    <button className="btn btn-dark mx-3">Modifer la sélection</button>
                                    <button className="btn btn-primary mx-3">Enregistrer</button>
                                    <button className="btn btn-success mx-3">Valider & verouiller</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default Allocation;