import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_WALLET_STATUS_COUNT,
  GET_WALLET_STATUS_COUNT_SUCCESS,
  GET_WALLET_STATUS_COUNT_FAIL
} from "./actionTypes"

export const getWallets = filterValue => ({
  type: GET_PROJECTS,
  filter: filterValue,
})

export const getWalletsSuccess = wallets => ({
  type: GET_PROJECTS_SUCCESS,
  payload: wallets,
})

export const addNewWallet = wallet => ({
  type: ADD_NEW_PROJECT,
  payload: wallet,
})

export const addWalletSuccess = wallet => ({
  type: ADD_PROJECT_SUCCESS,
  payload: wallet,
})

export const addWalletFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
})

export const updateWallet = wallet => ({
  type: UPDATE_PROJECT,
  payload: wallet,
})

export const updateWalletSuccess = wallet => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: wallet,
})

export const updateWalletFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
})

export const deleteWallet = wallet => ({
  type: DELETE_PROJECT,
  payload: wallet,
})

export const deleteWalletSuccess = wallet => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: wallet,
})

export const deleteWalletFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})

export const getWalletsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const getWalletDetail = walletId => ({
  type: GET_PROJECT_DETAIL,
  walletId,
})

export const getWalletDetailSuccess = walletDetails => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: walletDetails,
})

export const getWalletDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
})

export const getWalletsStatusCount = filter => ({
  type: GET_WALLET_STATUS_COUNT,
  payload: filter,
})

export const getWalletsStatusCountSuccess = (count, status) => ({
  type: GET_WALLET_STATUS_COUNT_SUCCESS,
  payload: count,
  status: status
})

export const getWalletsStatusCountFail = error => ({
  type: GET_WALLET_STATUS_COUNT_FAIL,
  payload: error,
})