import MetaTags from "react-meta-tags";
import React from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { twoAuthUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import PropTypes from "prop-types";

const TwoFactorAuthentification = ({username, password}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let navigate = useNavigate();

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        code: "123456" || '',
      },
      validationSchema: Yup.object({
        code: Yup.string().required("Please Enter Your Code"),
      }),
      onSubmit: (values) => {
        dispatch(twoAuthUser(username, password, values.code, navigate));
      }
    });

    const { error } = useSelector(state => ({
      error: state.Login.error,
    }));

    return (
        <React.Fragment>
            <MetaTags>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                <Row>
                                    <Col xs={7}>
                                    <div className="text-primary p-4">
                                        <h5 className="text-primary">{t('login.welcome')}</h5>
                                        <p>{t('login.EnterCode')}</p>
                                    </div>
                                    </Col>
                                    <Col className="col-5 align-self-end">
                                    <img src={profile} alt="" className="img-fluid" />
                                    </Col>
                                </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/" className="auth-logo-light">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                            <img
                                                src={logo}
                                                alt=""
                                                className="rounded-circle"
                                                height="34"
                                            />
                                            </span>
                                        </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                        >
                                            {error ? <Alert color="danger">{error}</Alert> : null}


                                            <div className="mb-3">
                                                <Label className="form-label">{t('Code')}</Label>
                                                <Input
                                                    name="code"
                                                    className="form-control"
                                                    placeholder={t('login.EnterCodeLabel')}
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.code || ""}
                                                    invalid={
                                                        validation.touched.code && validation.errors.code ? true : false
                                                    }
                                                />
                                                {validation.touched.code && validation.errors.code ? (
                                                    <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mt-3 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    type="submit"
                                                >
                                                    {t('login.Verify')}
                                                </button>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <Link to="/forgot-password" className="text-muted">
                                                    {t('login.ResendCode')}
                                                </Link>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    {t('GoBackTo')}{" "}
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        {t('login.LogIn')}
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

TwoFactorAuthentification.propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
}

export default TwoFactorAuthentification;

