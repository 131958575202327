import React from "react";
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import ItemList from "./ItemList";
import Cart from "./Cart";
import {useResource} from "../../components/Crud/hooks";

function Marketplace(props) {
    const resource = useResource('/api/v1/products', '/marketplace', 'marketplace')

    return (
        <div className={"page-content"}>
            <h2>{props.t('Product.Marketplace.Title')}</h2>
            <Row>
                <Col xs={12} md={8}>
                    <ItemList resource={resource} />
                </Col>
                <Col xs={12} md={4}>
                    <Cart resource={resource} />
                </Col>
            </Row>
        </div>
    )
}

Marketplace.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Marketplace)
