import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";
import {Badge, Col, Row} from "reactstrap";

function SummaryTable(props) {
    return (
        <>
            <h5 style={{fontWeight:'bold'}}>{props.t('Product.Marketplace.Cart.Title')}</h5>
            <div className={"p-2"}>{props.t('Product.Marketplace.Cart.Summary')}</div>

            <div className={"mt-2 mb-4"}>
                <Row className={"p-2 border-bottom"}>
                    <Col xs={6}>
                        <b>{props.t('Product.Marketplace.Cart.ProjectName')} :</b>
                    </Col>
                    <Col xs={6}>
                        Lorem
                    </Col>
                </Row>

                <Row className={"p-2 border-bottom"}>
                    <Col xs={6}>
                        <b>{props.t('Product.Marketplace.Cart.RiskProfile')} :</b>
                    </Col>
                    <Col xs={6}>
                        <Badge color={"warning"}>Lorem</Badge>
                    </Col>
                </Row>

                <Row className={"p-2 border-bottom"}>
                    <Col xs={6}>
                        <b>{props.t('Product.Marketplace.Cart.InvestmentDuration')} :</b>
                    </Col>
                    <Col xs={6}>
                        1-2 Lorem
                    </Col>
                </Row>

                <Row className={"p-2 border-bottom"}>
                    <Col xs={6}>
                        <b>{props.t('Product.Marketplace.Cart.InitialDeposit')} :</b>
                    </Col>
                    <Col xs={6}>
                        15000 Lorem
                    </Col>
                </Row>

                <Row className={"p-2 border-bottom"}>
                    <Col xs={6}>
                        <b>{props.t('Product.Marketplace.Cart.RecurrentDeposit')} :</b>
                    </Col>
                    <Col xs={6}>
                        250€ / Lorem
                    </Col>
                </Row>
            </div>
        </>
    )
}

SummaryTable.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(SummaryTable)
