import React from "react";
import {useSelector} from "react-redux";
import {selectToasts} from "../../store/toast/selectors";
import {Toast, ToastBody, ToastHeader} from "reactstrap";

function Toasts() {
    const toasts = useSelector(selectToasts)

    return (
        <div style={{position: 'absolute', right: '10px', bottom: '10px'}}>
            {toasts.map((toast, index) => (
                <div className={`rounded bg-${toast.color}`} key={index} >
                    <Toast isOpen title={toast.title} fade>
                        <ToastHeader>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {Boolean(toast.icon) && <i className={toast.icon} style={{fontSize: '1.2rem', marginRight: '10px'}} />}
                                <span>{toast.title}</span>
                            </div>
                        </ToastHeader>
                        <ToastBody>
                            {toast.body}
                        </ToastBody>
                    </Toast>
                </div>
            ))}
        </div>
    )
}

export default Toasts
