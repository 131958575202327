import {CART_ADD_PRODUCT, CART_REMOVE_PRODUCT, CART_RESET} from "./actionTypes";

const initialState = {
    products: [],
}

export default function cart(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_PRODUCT:
            return {...state, products: [...state.products, action.product]}

        case CART_REMOVE_PRODUCT:
            return {...state, products: state.products.filter(item => item.id !== action.product.id )}

        case CART_RESET:
            return {...state, products: []}

        default:
            return state
    }
}
