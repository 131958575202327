import PropTypes from 'prop-types'
import React from "react"

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import {connect} from "react-redux"

// Import Routes all
import {authProtectedRoutes, crudRoutes, publicRoutes} from "./routes"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import PrivateRoutes from 'routes/PrivateRoutes'
import Toasts from "./components/Toasts";

// Activating fake backend
fakeBackend()

const App = props => {

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <>
      <BrowserRouter>
        {crudRoutes.map((route, idx) => {
          const Component = route.component
          return (
              <Component key={idx} basePath={route.path} layout={Layout} />
          );
        })}
        <Routes>
          <Route element={<PrivateRoutes isAuthProtected={true}/>}>
              {
                authProtectedRoutes.map((route, idx) => {
                  return <Route key={idx} path={route.path} element={<Layout>{route.component}</Layout>} />
                })
              }
          </Route>
          {
            publicRoutes.map((route, idx) => {
              return <Route key={idx} path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} />
            })
          }
        </Routes>
      </BrowserRouter>
      <Toasts />
    </>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
