import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import {resourceShape} from "../../components/Crud/shapes"
import {withTranslation} from "react-i18next";
import PaymentMethodsTable from "./PaymentMethodsTable";
import {useResource} from "../../components/Crud/hooks";
import NewPaymentMethodTableRow from "./NewPaymentMethodForm/NewPaymentMethodTableRow";

function SelectPaymentMethodModal(props) {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(props.selectedRow)
    const [createOpen, setCreateOpen] = useState(props.selectedRow)
    const resource = props.resource ?? useResource(
        '/api/v1/payment_methods',
        '/payment-methods',
        'selectPaymentMethodModale',
    )

    useEffect(() => setSelectedPaymentMethod(props.selectedRow), [props.selectedRow])

    return (
        <Modal isOpen={props.isOpen} onClosed={props.onClosed} size={"lg"}>
            <ModalHeader>
                {props.t(props.title || 'PaymentMethod.SelectPaymentMethodModal.Title')}
            </ModalHeader>
            <ModalBody>
                <PaymentMethodsTable
                    resource={resource}
                    customer={props.customer}
                    rowClick={(evt, item) => setSelectedPaymentMethod(item)}
                    rowDoubleClick={(evt, item) => props.onSelected?.(item)}
                    selectedRow={selectedPaymentMethod}
                >
                    {/*<NewPaymentMethodTableRow resource={resource} colSpan={5} />*/}
                </PaymentMethodsTable>
            </ModalBody>
            <ModalFooter>
                <Button type={"button"} onClick={props.onClosed}>
                    {props.t('Cancel')}
                </Button>
                <Button disabled={!selectedPaymentMethod} type={"button"} color={"primary"} onClick={() => selectedPaymentMethod && props.onSelected?.(selectedPaymentMethod)}>
                    {props.t('Select')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

SelectPaymentMethodModal.propTypes = {
    onSelected: PropTypes.func,
    onClosed: PropTypes.func,
    isOpen: PropTypes.bool,
    resource: resourceShape,
    title: PropTypes.string,
    customer: PropTypes.string.isRequired,
    t: PropTypes.func,
    selectedRow: PropTypes.any,
}

export default withTranslation()(SelectPaymentMethodModal)
