let currentUsers = [
    {
      uid: 1,
      username: "admin",
      firstname: "martine",
      lastname: "dupont",
      phone: "0612345678",
      admin: true,
      password: "123456",
      email: "admin@hesiode.com",
      code2FA: "123456",
      companyName: "company 3000",
      companyAddress: "2 rue de la liberté",
      companyZipCode: "75000",
      companyCity: "Paris",
      companyEmail: "company@gmail.com",
      companyPhone: "0298765432",
    },
  ]

export { currentUsers }
