import React from "react"
import PropTypes from "prop-types"
import Crud from "../../components/Crud"
import PaymentMethodsPage from "./PaymentMethodsPage";

function PaymentMethod(props) {
    return (
        <Crud
            url={"/api/v1/payment_methods"}
            basePath={props.basePath}
            components={{list: PaymentMethodsPage}}
            layout={props.layout}
        />
    )
}

PaymentMethod.propTypes = {
    basePath: PropTypes.string.isRequired,
    layout: PropTypes.any.isRequired,
}

export default PaymentMethod
