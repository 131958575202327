import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { useNavigate, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";

//i18n
import { useTranslation } from "react-i18next"

// actions
import { editProfile, resetProfileFlag, getCurrentProfile } from "../../store/actions";

const EditProfile = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [password, setpassword] = useState("");
  const [phone, setphone] = useState("");
  const [idx, setidx] = useState(1);
  const [admin, setadmin] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [companyAddress, setcompanyAddress] = useState("");
  const [companyZipCode, setcompanyZipCode] = useState("");
  const [companyCity, setcompanyCity] = useState("");
  const [companyEmail, setcompanyEmail] = useState("");
  const [companyPhone, setcompanyPhone] = useState("");

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      
      setname(obj.username);
      setfirstname(obj.firstname);
      setlastname(obj.lastname);
      setemail(obj.email);
      setpassword(obj.password);
      setphone(obj.phone);
      setidx(obj.uid);
      setadmin(obj.admin);
      setcompanyName(obj.companyName);
      setcompanyAddress(obj.companyAddress);
      setcompanyZipCode(obj.companyZipCode);
      setcompanyCity(obj.companyCity);
      setcompanyEmail(obj.companyEmail);
      setcompanyPhone(obj.companyPhone);
        
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }

    dispatch(getCurrentProfile());
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      firstname: firstname || '',
      lastname: lastname || '',
      email: email || '',
      password: password || '',
      phone: phone || '',
      idx : idx || '',
      companyName: companyName || '',
      companyAddress: companyAddress || '',
      companyZipCode: companyZipCode || '',
      companyCity: companyCity || '',
      companyEmail: companyEmail || '',
      companyPhone: companyPhone || '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(t("ErrorMessage.firstname")),
      lastname: Yup.string().required(t("ErrorMessage.name")),
      email: Yup.string().required(t("ErrorMessage.email")),
      password: Yup.string().required(t("ErrorMessage.password")),
      phone: Yup.string().required(t("ErrorMessage.phone")),
      companyName: Yup.string().required(t("ErrorMessage.companyName")),
      companyAddress: Yup.string().required(t("ErrorMessage.companyAddress")),
      companyZipCode: Yup.string().required(t("ErrorMessage.companyZipCode")),
      companyCity: Yup.string().required(t("ErrorMessage.companyCity")),
      companyEmail: Yup.string().required(t("ErrorMessage.companyEmail")),
      companyPhone: Yup.string().required(t("ErrorMessage.companyPhone")),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });
  if ({admin} == true){

  }

  return (
    <React.Fragment>
        <div className="page-content">
        <MetaTags>
          <title>{t("Customer.editProfile")} | Hesiode</title>
        </MetaTags>
        <Container fluid>
        <Breadcrumb title="Hesiode" breadcrumbItem={t("Customer.editProfile")} />
            <Card>
                <CardBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return navigate("/profile");
                      }}
                    >
                      <img
                        src={avatar}
                        alt=""
                        className="avatar rounded-circle img-thumbnail position-relative top-0 start-50 translate-middle-x"
                      />
                      <div className="d-flex">
                        <div className="form-group p-2 flex-grow-1 bd-highlight">
                            <Input name="idx" value={idx} type="hidden" />

                            <Label className="form-label fw-bold mt-2">{t("Customer.firstname")}</Label>
                            <Input
                              name="firstname"
                              // value={firstname}
                              className="form-control"
                              placeholder={t("Customer.enterFirstname")}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstname || ""}
                              invalid={
                                  validation.touched.firstname && validation.errors.firstname ? true : false
                              }
                            />
                            {validation.touched.firstname && validation.errors.firstname ? (
                            <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                            ) : null}

                            <Label className="form-label fw-bold mt-2">{t("Customer.name")}</Label>
                            <Input
                              name="lastname"
                              // value={lastname}
                              className="form-control"
                              placeholder={t("Customer.enterName")}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastname || ""}
                              invalid={
                                  validation.touched.lastname && validation.errors.lastname ? true : false
                              }
                            />
                            {validation.touched.lastname && validation.errors.lastname ? (
                            <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                            ) : null}

                            <Label className="form-label fw-bold mt-2">{t("Customer.mail")}</Label>
                            <Input
                              name="email"
                              // value={email}
                              className="form-control"
                              placeholder={t("Customer.enterMail")}
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}

                            <Label className="form-label fw-bold mt-2">{t("Customer.password")}</Label>
                            <Input
                              name="password"
                              // value={password}
                              className="form-control"
                              placeholder={t("Customer.enterPassword")}
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}

                            <Label className="form-label mt-2">{t("Customer.phone")}</Label>
                            <Input
                              name="phone"
                              // value={phone}
                              className="form-control"
                              placeholder={t("Customer.enterPhone")}
                              type="tel"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ""}
                              invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                              }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                        </div>
                      </div>
                        {admin ? ( //if admin
                            <div className="form-group p-2 flex-grow-1 bd-highlight">
                                <h4 className="mb-3 mt-3">{t("Customer.editCompany")}</h4>
                                <Label className="form-label fw-bold">{t("Customer.companyName")}</Label>
                                <Input
                                name="companyName"
                                // value={companyName}
                                className="form-control"
                                placeholder={t("Customer.enterCompanyName")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyName || ""}
                                invalid={
                                    validation.touched.companyName && validation.errors.companyName ? true : false
                                }
                                />
                                {validation.touched.companyName && validation.errors.companyName ? (
                                <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                ) : null}

                                <Input name="idx" value={idx} type="hidden" />

                                <Label className="form-label fw-bold mt-2">{t("Customer.companyAddress")}</Label>
                                <Input
                                name="companyAddress"
                                // value={companyAddress}
                                className="form-control"
                                placeholder={t("Customer.entercompanyAddress")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyAddress || ""}
                                invalid={
                                    validation.touched.companyAddress && validation.errors.companyAddress ? true : false
                                }
                                />
                                {validation.touched.companyAddress && validation.errors.companyAddress ? (
                                <FormFeedback type="invalid">{validation.errors.companyAddress}</FormFeedback>
                                ) : null}

                                <Label className="form-label fw-bold mt-2">{t("Customer.companyZipCode")}</Label>
                                <Input
                                name="companyZipCode"
                                // value={companyZipCode}
                                className="form-control"
                                placeholder={t("Customer.enterCompanyZipCode")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyZipCode || ""}
                                invalid={
                                    validation.touched.companyZipCode && validation.errors.companyZipCode ? true : false
                                }
                                />
                                {validation.touched.companyZipCode && validation.errors.companyZipCode ? (
                                <FormFeedback type="invalid">{validation.errors.companyZipCode}</FormFeedback>
                                ) : null}

                                <Label className="form-label fw-bold mt-2">{t("Customer.companyCity")}</Label>
                                <Input
                                name="companyCity"
                                // value={companyCity}
                                className="form-control"
                                placeholder={t("Customer.enterCompanyCity")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyCity || ""}
                                invalid={
                                    validation.touched.companyCity && validation.errors.companyCity ? true : false
                                }
                                />
                                {validation.touched.companyCity && validation.errors.companyCity ? (
                                <FormFeedback type="invalid">{validation.errors.companyCity}</FormFeedback>
                                ) : null}

                                <Label className="form-label fw-bold mt-2">{t("Customer.companyEmail")}</Label>
                                <Input
                                name="companyEmail"
                                // value={companyEmail}
                                className="form-control"
                                placeholder={t("Customer.enterCompanyMail")}
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyEmail || ""}
                                invalid={
                                    validation.touched.companyEmail && validation.errors.companyEmail ? true : false
                                }
                                />
                                {validation.touched.companyEmail && validation.errors.companyEmail ? (
                                <FormFeedback type="invalid">{validation.errors.companyEmail}</FormFeedback>
                                ) : null}

                                <Label className="form-label fw-bold mt-2">{t("Customer.companyPhone")}</Label>
                                <Input
                                name="companyPhone"
                                // value={companyPhone}
                                className="form-control"
                                placeholder={t("Customer.enterCompanyPhone")}
                                type="tel"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyPhone || ""}
                                invalid={
                                    validation.touched.companyPhone && validation.errors.companyPhone ? true : false
                                }
                                />
                                {validation.touched.companyPhone && validation.errors.companyPhone ? (
                                <FormFeedback type="invalid">{validation.errors.companyPhone}</FormFeedback>
                                ) : null}
                            </div>
                        ) : ( '')}
                      <div className="text-center mt-4 mb-2">
                        <Link to="/profile" className="btn btn-secondary me-2">
                          {t("Cancel")}
                        </Link>
                        <Button type="submit" color="primary">
                          {t("Customer.update")}
                        </Button>
                      </div>
                    </Form>
                </CardBody>
            </Card>
        </Container>
        </div>
    </React.Fragment>
    );
};

export default EditProfile;
