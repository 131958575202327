import React, { useEffect, useState, Component } from "react"
import PropTypes from 'prop-types'
import {connect, useDispatch, useSelector} from "react-redux"
import MetaTags from 'react-meta-tags';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Label,
  Input,
  CardTitle,
  InputGroup,
  Table
} from "reactstrap"
import Select from "react-select"
import {useLocation, useNavigate} from "react-router-dom"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Images
import images from "assets/images";

import { getCustomerProfileAction, getCustomers } from "store/actions"

//i18n
import { useTranslation } from "react-i18next"

//NewCustomerModal
import NewCustomerModal from "components/Common/NewCustomerModal";
import UpdatePatrimonyModal from "components/Common/UpdatePatrimonyModal";
import UpdateCustomerPersonnalInformationModal from "components/Common/UpdateCustomerPersonnalInformationModal";
import UpdateEarningModal from "components/Common/UpdateEarningModal";
import SelectPaymentMethodModal from "../../cruds/PaymentMethod/SelectPaymentMethodModale";
import {useResource} from "../../components/Crud/hooks";
import {filterObject} from "../../helpers/object_utils";
import {sum} from "lodash";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function transformDurationToMin(value) {
    switch(value) {
        case '1': return null;
        case '2': return 6;
        case '3': return 12;
        case '4': return 24;
        case '5': return 36;
    }
}

function transformDurationToMax(value) {
    switch(value) {
        case '1': return 6;
        case '2': return 12;
        case '3': return 24;
        case '4': return 36;
        case '5': return null;
    }
}

const floatSum = items => sum(items.map(item => parseFloat(item || 0)))

const NewWallet = props => {
    const { t } = useTranslation();
    const query = useQuery();
    const dispatch = useDispatch();
    const resource = useResource('/api/v1/projects','/projects', 'new_project_last_project')
    const [paymentMethodOpen, setPaymentMethodOpen] = useState(false)

    const customerList = props.customers;

    const [earning, setEarning] = useState(null)
    const [patrimony, setPatrimony] = useState(null)
    const [searchQuery,setSearchQuery] = useState('');
    const [showProgrammedPaymentsSection, setShowProgrammedPaymentsSection] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [currentProject, setCurrentProject] = useState({
        name: "",
        description: "",
        risk: null,
        investmentDuration: null,
        amountInitialPayments: 0,
        amountProgrammedPayments: 0,
        paymentFrequency: null
    });
    
    //pagination customization
    const pageOptions = {
        sizePerPage: 5,
        totalSize: customerList.length, // replace later with size,
        custom: true,
    }
    const selectRow = {
        mode: 'radio', // single row selection
        clickToSelect: true,
        hideSelectColumn: true,
        classes: 'bg-primary bg-opacity-10',
        onSelect: (row, isSelect, rowIndex, e) => {
            setCurrentCustomer(row)
          }
      };

    const ProjetColumns = [{
            dataField: "id",
            isDummyField: true,
            hidden: true,
            text: " ",
        },
        {
            dataField: "img",
            text: " ",
            style:  { width: "4.5rem" },
            formatter: (cellContent, row) => (
                <div className="mx-auto avatar-sm rounded-circle img-thumbnail bg-primary bg-opacity-25 text-primary fw-bold font-size-18 d-flex justify-content-center align-items-center">
                    {(row.first_name ?? row.username ?? '').substring(0, 1).toUpperCase()}
                </div>
            )
        },
        {
            dataField: "firstName",
            text: " ",
            formatter: (cellContent, row) => (
                <div>
                    <div className="fs-6">
                        <span className="me-2">{row.first_name}</span>
                        <span>{row.last_name}</span>
                    </div>
                    <div>{row.email}</div>
                </div>
            )
        },
    ]

    //search bar
    function handleInputChange(e){
        const searchTargetValue = e.target.value;
        setSearchQuery(searchTargetValue);
        dispatch(getCustomers(searchTargetValue))
    }

    function toggleProgrammedPaymentsSection(valueToSet) {
        setShowProgrammedPaymentsSection(valueToSet)
        if (valueToSet) {
            setCurrentProject(current => ({ ...current, amountProgrammedPayments: 0}))
            setCurrentProject(current => ({ ...current, paymentFrequency: null}))
        }
    }

    const options = [
        { label: t("LessThan6Months"), value: "1" },
        { label: t("Between6And12Months"), value: "2" },
        { label: t("Between12And24Months"), value: "3" },
        { label: t("Between24And36Months"), value: "4" },
        { label: t("MoreThan36Months"), value: "5" },
    ]
    const riskList = [
        { id: 1, label: t("Wallet.careful"), value: "careful"},
        { id: 2, label: t("Wallet.balanced"), value: "balanced"},
        { id: 3, label: t("Wallet.dynamic"), value: "dynamic"},
    ]
    const frequency = [
        { label: t("Wallet.weekly"), value: "weekly" },
        { label: t("Wallet.twiceAMonth"), value: "twice_a_month" },
        { label: t("Wallet.monthly"), value: "monthly" },
    ]

    if (query.has("customerId")) {
        const customerId = query.get("customerId")

        const onGetCustomerProfile = props.onGetCustomerProfile
        useEffect(() => {
            onGetCustomerProfile(customerId)
        }, [onGetCustomerProfile])

        if (!currentCustomer || (props.currentCustomer && currentCustomer.id !== props.currentCustomer.id)) {
            setCurrentCustomer(props.currentCustomer)
        }
    } else {
        const onGetCustomers = props.onGetCustomers

        useEffect(() => {
            onGetCustomers()
        }, [onGetCustomers])
    }

    const updateRisk = (event) => {
        setCurrentProject(current => ({...current, risk: event.target.value}))
    }
    const updateName = (event) => {
        setCurrentProject(current => ({ ...current, name: event.target.value}))
    }
    const updateDescription = (event) => {
        setCurrentProject(current => ({ ...current, description: event.target.value}))
    }
    const updateInvestmentDuration = (value) => {
        setCurrentProject(current => ({ ...current, investmentDuration: value}))
    }
    const updateAmountInitialPayments = (event) => {
        setCurrentProject(current => ({ ...current, amountInitialPayments: event.target.value}))
    }
    const updateAmountProgrammedPayments = (event) => {
        setCurrentProject(current => ({ ...current, amountProgrammedPayments: event.target.value}))
    }
    const updatePaymentFrequency = (value) => {
        setCurrentProject(current => ({ ...current, paymentFrequency: value}))
    }

    const isFormValid = () => {
        return Boolean(
            currentCustomer?.id &&
            currentProject.name.length > 3 &&
            currentProject.risk &&
            (currentProject.investmentDuration && (currentProject.amountInitialPayments > 0 || currentProject.amountProgrammedPayments > 0 && currentProject.paymentFrequency))
        )
    }

    const submitForm = () => {
        resource.create(filterObject({
            user: currentCustomer?.id,
            payment_method: paymentMethod?.id,
            risk_profile: currentProject.risk,
            name: currentProject.name,
            description: currentProject.description,
            min_duration: transformDurationToMin(currentProject.investmentDuration),
            max_duration: transformDurationToMax(currentProject.investmentDuration),
            initial_amount: currentProject.amountInitialPayments,
            recurrence: showProgrammedPaymentsSection ? currentProject.paymentFrequency.value : null,
            recurrent_amount: showProgrammedPaymentsSection ? currentProject.amountProgrammedPayments : null,
        }))
    }

    const createdItem = useSelector(resource.selectCreatedItem())
    const navigate = useNavigate()

    useEffect(() => {
        if (createdItem?.id) {
            resource.resetCreate()
            navigate('/projects/' + createdItem.id)
        }
    }, [createdItem])

    function showInvestors() {
        return (
            <div>
                <label>{t("SelectACustomer")}</label>
                <SimpleBar style={{ maxHeight: "250px" }}>
                    <div className="table-responsive">
                        <Table className="table table-nowrap align-middle table-hover mb-0">
                            <tbody>
                            {
                                customerList.map((customer) => (
                                    <tr
                                        key={ customer.id }
                                        role="button"
                                        onClick={ () => setCurrentCustomer(customer) }
                                        className={ currentCustomer && (currentCustomer.id === customer.id) ? "bg-primary bg-opacity-10": "" }
                                    >
                                        <td style={{ width: "4.5rem" }}>
                                            <img
                                                src={images[customer.img]}
                                                alt=""
                                                className="mx-auto avatar-sm rounded-circle img-thumbnail"
                                            />
                                        </td>
                                        <td>
                                            <div className="fw-bold fs-6"><span className="me-2">{customer.firstName}</span><span>{customer.name}</span></div>
                                            <div>{customer.email}</div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </div>
                </SimpleBar>
            </div>
            )
    }

    const currencySymbol = paymentMethod?.currency?.symbol || '€'

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Project.addNewProject')} | Hesiode</title>
                </MetaTags>
                <Container fluid>
                    <h4>{t('Project.addNewProject')}</h4>
                    
                    <Row className="d-flex justify-content-center">
                        <Col lg="6">
                            <Card className={ currentCustomer && currentCustomer.name ? "border border-primary border-2" : ""}>
                    {customerList.length === 0 ? (
                        <CardBody>
                            <div className="mb-4 d-flex justify-content-between align-items-center">
                                        <CardTitle>{t("Customer.personnalInformation")}</CardTitle>
                                        {
                                            query.has("customerId") && currentCustomer
                                                ? <UpdateCustomerPersonnalInformationModal />
                                                : <NewCustomerModal/>
                                        }
                            </div>
                            <p className="text-danger">{t("Customer.noCustomer")}</p>
                        </CardBody>
                    ): (
                                <CardBody>
                                    <div className="mb-4 d-flex justify-content-between align-items-center">
                                        <CardTitle>{t("Customer.personnalInformation")}</CardTitle>
                                        {
                                            query.has("customerId") && currentCustomer
                                                ? <UpdateCustomerPersonnalInformationModal />
                                                : <NewCustomerModal/>
                                        }
                                    </div>
                                    {query.has("customerId") && currentCustomer
                                    ? 
                                    <div>
                                        <div className="">
                                            <div className="text-center">
                                                <img
                                                    src={images[currentCustomer.img]}
                                                    alt=""
                                                    className="mx-auto avatar-md rounded-circle img-thumbnail"
                                                />
                                                {/* namen firstname */}
                                                <h5 className="text-center">{currentCustomer.firstName} {currentCustomer.name}</h5>
                                            </div>
                                        </div>
                                        <p className="text-muted mb-2">
                                            {currentCustomer.comment}
                                        </p>
                                        <div className="table-responsive mb-3">
                                            <Table className="table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">{t("Customer.maritalStatus")}:</th>
                                                    <td>{currentCustomer.maritalStatus}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t("Customer.phone")}:</th>
                                                    <td>{currentCustomer.phone}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t("Email")}:</th>
                                                    <td>{currentCustomer.email}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t("Customer.address")}:</th>
                                                    <td>{currentCustomer.address} {currentCustomer.zipcode} {currentCustomer.city}</td>
                                                </tr>
                                            </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    : 
                                    <div>
                                        <div>
                                            <form className="app-search d-none d-lg-block">
                                                <div className="position-relative">
                                                <input
                                                    type="text"
                                                    id="search"
                                                    name="search"
                                                    onChange={handleInputChange}
                                                    value={searchQuery}
                                                    className="form-control"
                                                    placeholder={t("Search") + "..."}
                                                />
                                                    <span className="bx bx-search-alt" />
                                                </div>
                                            </form>
                                                <label className="text-primary">{t("SelectACustomer")}</label>
                                                {/* test */}
                                                <PaginationProvider
                                                    pagination={paginationFactory(pageOptions)}
                                                    keyField="id"
                                                    columns={ProjetColumns}
                                                    data={customerList}
                                                >
                                                    {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        data={customerList}
                                                        columns={ProjetColumns}
                                                        bootstrap4
                                                        search
                                                    >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField="id"
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    selectRow={ selectRow }
                                                                    classes={
                                                                        "table table-nowrap align-middle table-hover mb-0"
                                                                    }
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                />
                                                            </div>
                                                            <div className="pagination pagination-rounded justify-content-end mt-3">
                                                                <PaginationListStandalone {...paginationProps} />
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                    </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>
                                        </div>
                                    </div>
                                    }
                                </CardBody>
                    )}
                            </Card>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center">
                        <Col lg="6">
                            <Card className={ currentCustomer && currentCustomer.patrimony && currentCustomer.earning ? "border border-primary border-2" : ""}>
                                <CardBody>
                                    <div className="mb-4 d-flex justify-content-between align-items-center">
                                        <CardTitle>{t("Customer.patrimony")}</CardTitle>
                                        
                                    </div>
                                    {
                                    currentCustomer ?
                                    <div className="text-center">
                                        <Row>
                                            <Col>
                                                <p className="text-muted mb-2 text-truncate">{t("Customer.patrimony")}</p>
                                                    <h5>{currentCustomer.patrimony}</h5>
                                                    <p>{Boolean(patrimony) && patrimony > 0 && `${patrimony} ${currencySymbol}`}</p>
                                                    <UpdatePatrimonyModal
                                                        minimal
                                                        customer={currentCustomer.id}
                                                        onLoad={patrimony => setPatrimony(floatSum(Object.values(patrimony)))}
                                                        onSubmit={newPatrimony => setPatrimony(floatSum(Object.values(newPatrimony)))}
                                                    />
                                            </Col>
                                            <Col>
                                                <p className="text-muted mb-2 text-truncate">{t("Customer.earning")}</p>
                                                    <h5>{currentCustomer.earning}</h5>
                                                    <p>{Boolean(earning) && earning > 0 && `${earning} ${currencySymbol}`}</p>
                                                    <UpdateEarningModal
                                                        minimal
                                                        customer={currentCustomer.id}
                                                        onLoad={earning => setEarning(floatSum(Object.values(earning)))}
                                                        onSubmit={newEarning => setEarning(floatSum(Object.values(newEarning)))}
                                                    />
                                            </Col>
                                        </Row>
                                        <div className="my-2">
                                            <div className="text-muted mb-2 text-truncate">{t("Customer.paymentMethod")}</div>
                                                <h5>{paymentMethod?.iban}</h5>

                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        setPaymentMethodOpen(true)
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#myModal"
                                                    outline={true}
                                                >
                                                    <i className={"fas fa-pen"}/>
                                                </Button>
                                                <SelectPaymentMethodModal
                                                    customer={currentCustomer.id}
                                                    isOpen={paymentMethodOpen}
                                                    onSelected={(item) => {
                                                        setPaymentMethodOpen(false)
                                                        setPaymentMethod(item)
                                                    }}
                                                    selectedRow={paymentMethod}
                                                    onClosed={() => setPaymentMethodOpen(false)}
                                                />
                                        </div>
                                    </div>
                                    :
                                    <p>{t("YouNeedToSelectACustomer")}</p>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center">
                        <Col lg="6">
                            <Card className={ currentProject.name.length > 3 ? "border border-primary border-2" : ""}>
                                <CardBody>
                                <CardTitle>
                                    {t("Wallet.information")}
                                </CardTitle>
                                <Form>
                                    <Row>
                                        <Col md="6" lg="12" xl="6">
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-name">{t("Wallet.name")}</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-name"
                                                    placeholder={t("Wallet.enterName")}
                                                    data-placement="bottom"
                                                    title={t("Wallet.nameTooltip")}
                                                    value={currentProject.name}
                                                    onChange={updateName}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" lg="12" xl="6">
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-description">{t("Wallet.description")}</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-description"
                                                    placeholder={t("Wallet.enterDescription")}
                                                    value={currentProject.description}
                                                    onChange={updateDescription}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center">
                        <Col lg="6">
                            <Card className={ currentProject.risk ? "border border-primary border-2" : ""}>
                                <CardBody>
                                <CardTitle>
                                    {t("Wallet.riskProfil")}
                                </CardTitle>
                                <Form>
                                    <Row>
                                        {
                                            riskList.map(risk => 
                                                <Col key={risk.id} md="4" lg="12" xl="4">
                                                    <div className="mb-3">
                                                        <Label className="card-radio-label mb-2">
                                                            <Input
                                                                type="radio"
                                                                name="riskOptions"
                                                                id={"riskOption" + risk.id}
                                                                value={risk.value}
                                                                onClick={updateRisk}    
                                                                className="card-radio-input"
                                                            />
                                                            <div className="card-radio">
                                                                <div className="text-center">
                                                                    <span className="fs-5 align-middle">{risk.label}</span>
                                                                </div>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center">
                        <Col lg="6">
                            <Card className={ currentProject.investmentDuration && (currentProject.amountInitialPayments > 0 || currentProject.amountProgrammedPayments > 0 && currentProject.paymentFrequency) ? "border border-primary border-2" : ""}>
                                <CardBody>
                                    <CardTitle>
                                        {t("Wallet.investmentProject")}
                                    </CardTitle>
                                    <Form className="mt-3">
                                        <Row>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-name" className="control-label">{t("Wallet.investmentDuration")}</Label>
                                                    <Select
                                                        classNamePrefix="select2-selection"
                                                        title="investmentDuration"
                                                        options={options}
                                                        id="formrow-name"
                                                        placeholder={t("Wallet.selectDuration")}
                                                        value={currentProject.investmentDuration}
                                                        onChange={s => {updateInvestmentDuration(s)}}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="12" className="mb-3">
                                                <Label htmlFor="formrow-amount">
                                                    {t("Wallet.amountInitialPayments")}
                                                    { showProgrammedPaymentsSection ? <small className="mx-2 text-primary">{t("Customer.optionnal")}</small> : null }
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className=""
                                                    id="formrow-amount"
                                                    placeholder={t("Wallet.enterAmount")}
                                                    value={currentProject.amountInitialPayments}
                                                    onChange={updateAmountInitialPayments}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <div className="form-check form-switch form-switch-lg mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customSwitchsizelg"
                                                        onChange={() => toggleProgrammedPaymentsSection(!showProgrammedPaymentsSection)}
                                                        defaultChecked={showProgrammedPaymentsSection}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customSwitchsizelg"
                                                    >
                                                        {t("prevoir des prélèvements programmés")}
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            showProgrammedPaymentsSection ? (
                                                <Row>
                                                    <Col sm="12">
                                                        <Label htmlFor="formrow-amount">{t("Wallet.amountProgrammedPayments")}</Label>
                                                        <Input
                                                            type="text"
                                                            className="w-100"
                                                            id="formrow-amount"
                                                            placeholder={t("Wallet.enterAmount")}
                                                            value={currentProject.amountProgrammedPayments}
                                                            onChange={updateAmountProgrammedPayments}
                                                        />
                                                    </Col>
                                                    <Col sm="12">
                                                        <Label htmlFor="formrow-name" className="control-label">{t("Wallet.paymentFrequency")}</Label>
                                                        <Select
                                                            classNamePrefix="select2-selection"
                                                            title="paymentFrequency"
                                                            options={frequency}
                                                            id="formrow-name"
                                                            placeholder={t("Wallet.selectFrequency")}
                                                            value={currentProject.paymentFrequency}
                                                            onChange={s => {updatePaymentFrequency(s)}}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : null
                                        }
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-center mb-4">
                        <Col sm="6">
                        <div>
                        <Button color="primary" type="submit" size="lg" className="w-100" disabled={!isFormValid()} onClick={submitForm}>
                            {t("Create")}
                        </Button>
                        </div>
                        </Col>
                    </Row>
                </Container>
                
            </div>
        </React.Fragment>
    )
}

NewWallet.propTypes = {
    customers: PropTypes.any,
    currentCustomer: PropTypes.any,
    onGetCustomerProfile: PropTypes.func,
    onGetCustomers: PropTypes.func,
    t: PropTypes.any
}

const mapStateToProps = state => ({ ...state.customer })

const mapDispatchToProps = dispatch => ({
    onGetCustomers: () => dispatch(getCustomers()),
    onGetCustomerProfile: customerId => dispatch(getCustomerProfileAction(customerId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewWallet);
