import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import PaymentMethodsTable from "./PaymentMethodsTable";
import {resourceShape} from "../../components/Crud/shapes";
import {useParams} from "react-router-dom";

function PaymentMethodsPage(props) {
    const customer = useParams().customer

    return (
        <div className={"page-content"}>
            <h4>{props.t('Payment methods')}</h4>

            <PaymentMethodsTable
                resource={props.resource}
                customer={customer}
            />
        </div>
    )
}

PaymentMethodsPage.propTypes = {
    t: PropTypes.func,
    resource: resourceShape.isRequired,
}

export default withTranslation()(PaymentMethodsPage)
