import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {resourceShape} from "../../../components/Crud/shapes";
import {useSelector} from "react-redux";
import {Button, Spinner} from "reactstrap";
import ButtonSpinner from "../../../components/Common/ButtonSpinner";

function Actions(props) {
    const loading = useSelector(props.resource.selectCreateItemLoading())

    return (
        <>
            <Button type="button" onClick={props.onCancel}>
                {props.t('Cancel')}
            </Button>
            <Button type="button" color="primary" disabled={!props.submit} onClick={props.onSubmit}>
                {!loading ? props.t('Submit') : <ButtonSpinner /> }
            </Button>
        </>
    )
}

Actions.propTypes = {
    t: PropTypes.func.isRequired,
    resource: resourceShape.isRequired,
    submit: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
}

export default withTranslation()(Actions)
