
const customersListMockData = [
  {
    id: 1,
    firstName: "Cynthia",
    name: "Price",
    maritalStatus: "célibataire",
    phone: "0612345678",
    email: "cynthiaprice@gmail.com",
    address: "3 Rue du commerce",
    zipcode: "69007",
    city: "Lyon",
    img: "avatar1",
    comment:
      "Ceci est un commentaire sur ce client.",
    patrimony: "1200000€",
    earning: "89000€",
    iban: "FR7612548029989876543210917",
    wallets: [
        {
          id: 1,
          name: "Cynthia Price",
          balance: "332504€",
          state: "In signature"
        },
        {
          id: 8,
          name: "Cynthia Price",
          balance: "3456000€",
          state: "Draft"
        },
    ],
    events: [
        {
          date: "15 Mar",
          desc: "If several languages coalesce of the resulting.",
        },
        {
          date: "14 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "13 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "13 Mar", desc: "To achieve this, it would be necessary." },
        { date: "12 Mar", desc: "Cum sociis natoque penatibus et magnis dis." },
        {
          date: "11 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "10 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "9 Mar", desc: "To achieve this, it would be necessary." },
      ]
  },{
    id: 2,
    firstName: "Paul",
    name: "Dupuit",
    maritalStatus: "marié",
    phone: "0612345678",
    email: "paul-dupuis@gmail.com",
    address: "7 place du marché",
    zipcode: "69003",
    city: "Lyon",
    img: "avatar2",
    comment:
      "Ceci est un commentaire sur ce client.",
    patrimony: "850000€",
    earning: "75000€",
    iban: "FR3512548123989876543210971",
    wallets: [
        {
          id: 2,
          name: "Paul Dupuit",
          balance: "$2,517.79",
          state: "In signature"
        },
        {
          id: 9,
          name: "Paul Dupuit",
          balance: "$1,120.64",
          state: "In progress"
        },
    ],
    events: [
        {
          date: "15 Mar",
          desc: "If several languages coalesce of the resulting.",
        },
        {
          date: "14 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "13 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "13 Mar", desc: "To achieve this, it would be necessary." },
        { date: "12 Mar", desc: "Cum sociis natoque penatibus et magnis dis." },
        {
          date: "11 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "10 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "9 Mar", desc: "To achieve this, it would be necessary." },
      ]
  },{
    id: 3,
    firstName: "Frédérique",
    name: "Doyon",
    maritalStatus: "célibataire",
    phone: "0191202814",
    email: "FrederiqueDoyon@dayrep.com",
    address: "25, rue La Boétie",
    zipcode: "75015",
    city: "Paris",
    img: "avatar3",
    comment:
      "Ceci est un commentaire sur ce client.",
    patrimony: "2500000€",
    earning: "79000€",
    iban: "FR7512548029989876543210917",
    wallets: [
        {
          id: 3,
          name: "Frédérique Doyon",
          balance: "172504€",
          state: "In signature"
        },
        {
          id: 10,
          name: "Frédérique Doyon",
          balance: "3456000€",
          state: "Draft"
        },
    ],
    events: [
        {
          date: "15 Mar",
          desc: "If several languages coalesce of the resulting.",
        },
        {
          date: "14 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "13 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "13 Mar", desc: "To achieve this, it would be necessary." },
        { date: "12 Mar", desc: "Cum sociis natoque penatibus et magnis dis." },
        {
          date: "11 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "10 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "9 Mar", desc: "To achieve this, it would be necessary." },
      ]
  },{
    id: 4,
    firstName: "Francis",
    name: "Sansouci",
    maritalStatus: "célibataire",
    phone: "0346538243",
    email: "FrancisSansouci@dayrep.com",
    address: "32, rue de la Hulotais",
    zipcode: "02100",
    city: "SAINT-QUENTIN",
    img: "avatar4",
    comment:
      "Ceci est un commentaire sur ce client.",
    patrimony: "400000€",
    earning: "14000€",
    iban: "FR7512548029989876543210917",
    wallets: [
        {
          id: 4,
          name: "Francis Sansouci",
          balance: "172504€",
          state: "In signature"
        },
        {
          id: 11,
          name: "Francis Sansouci",
          balance: "3456000€",
          state: "Draft"
        },
    ],
    events: [
        {
          date: "15 Mar",
          desc: "If several languages coalesce of the resulting.",
        },
        {
          date: "14 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "13 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "13 Mar", desc: "To achieve this, it would be necessary." },
        { date: "12 Mar", desc: "Cum sociis natoque penatibus et magnis dis." },
        {
          date: "11 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "10 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "9 Mar", desc: "To achieve this, it would be necessary." },
      ]
  },{
    id: 5,
    firstName: "Alexandrie",
    name: "Sevier",
    maritalStatus: "célibataire",
    phone: "0174187858",
    email: "AlexandrieSevier@rhyta.com",
    address: "35, rue du Paillle en queue",
    zipcode: "94240",
    city: "L'HAŸ-LES-ROSES",
    img: "avatar5",
    comment:
      "Ceci est un commentaire sur ce client.",
    patrimony: "400000€",
    earning: "14000€",
    iban: "FR7512548029989876543210917",
    wallets: [
        {
          id: 5,
          name: "Alexandrie Sevier",
          balance: "172504€",
          state: "In signature"
        },
        {
          id: 12,
          name: "Alexandrie Sevier",
          balance: "3456000€",
          state: "Draft"
        },
    ],
    events: [
        {
          date: "15 Mar",
          desc: "If several languages coalesce of the resulting.",
        },
        {
          date: "14 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "13 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "13 Mar", desc: "To achieve this, it would be necessary." },
        { date: "12 Mar", desc: "Cum sociis natoque penatibus et magnis dis." },
        {
          date: "11 Mar",
          desc: "New common language will be more simple and regular than the existing.",
        },
        {
          date: "10 Mar",
          desc: "It will seem like simplified English as a skeptical Cambridge.",
        },
        { date: "9 Mar", desc: "To achieve this, it would be necessary." },
    ]
  }
]

export { customersListMockData }