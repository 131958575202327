import React, { useEffect, useState, Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Form,
    Label,
    Input,
    Table
  } from "reactstrap"

import { getCustomers } from "store/actions"

//i18n
import { useTranslation } from "react-i18next"

//NewCustomerModal
import NewCustomerModal from "components/Common/NewCustomerModal";
import moment from "moment-with-locales-es6"

const MyCustomers = props => {
  const { t } = useTranslation()
    const { customers, onGetCustomers } = props

    useEffect(() => {
        onGetCustomers()
    }, [onGetCustomers])

    const CustomerColumns = [
        {
          dataField: "id",
          text: t("id"),
          sort: true
        },
        {
          dataField: "first_name",
          text: t("Customer.lastname"),
          sort: true,
          formatter: function formatter(cellContent, row) {
            return (<Link to={`/customer/${row.id}`}>{row.first_name + " " + row.last_name}</Link>)
          }
        },
        {
          dataField: "phone",
          text: t("Customer.phone"),
          sort: true
        },
        {
          dataField: "email",
          text: t("Email"),
          sort: true
        },
        {
          dataField: "address.zip_code",
          text: t("Customer.zipcode"),
          sort: true
        },
        {
          dataField: "address.city",
          text: t("Customer.city"),
          sort: true,
        },
        {
          dataField: "created_at",
          text: t("Customer.createdAt"),
          sort: true,
          formatter: (cellContent, row) => moment(row.created_at).format('LLLL')
        },
        {
          dataField: "action",
          isDummyField: true,
          text: t("Action"),
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
            >
              {t("Edit")}
            </Button>
          ),
        },
    ]
    
    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: customers.length, // replace later with size(customers),
      custom: true,
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('MyCustomers')} | Hesiode</title>
                </MetaTags>
                <Container fluid>
                    <h4>{t('MyCustomers')}</h4>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                        <div className="h4 card-title">{t('Customers')}</div>
                                        <div>
                                          <NewCustomerModal/>
                                        </div>
                                    </div>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={CustomerColumns}
                                        data={customers}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            data={customers}
                                            columns={CustomerColumns}
                                            bootstrap4
                                            search
                                        >
                                        {toolkitProps => (
                                            <React.Fragment>
                                            <Row>
                                                <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField="id"
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        classes={
                                                            "table align-middle table-nowrap table-check"
                                                        }
                                                        headerWrapperClasses={"table-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                    />
                                                </div>
                                                </Col>
                                            </Row>
                                            <div className="pagination pagination-rounded justify-content-end">
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                            </React.Fragment>
                                        )}
                                        </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

MyCustomers.propTypes = {
    customers: PropTypes.any,
    onGetCustomers: PropTypes.func,
}

const mapStateToProps = state => ({ ...state.customer })

const mapDispatchToProps = dispatch => ({
    onGetCustomers: () => dispatch(getCustomers()),
})

export default connect(
        mapStateToProps,
        mapDispatchToProps
    )(MyCustomers)
