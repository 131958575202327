import React from "react"
import PropTypes from "prop-types"
import {initResourceItem, useResource} from "./hooks"
import {Route, Routes} from "react-router-dom"
import DeleteModale from "./DeleteModale"

function Crud(props) {
    const resource = useResource(props.url, props.basePath)
    const DeleteModalImpl = props.components?.deleteModal ?? DeleteModale
    const Layout = props.layout
    const List = props.components?.list

    return (
        <>
            <DeleteModalImpl onDeleteSuccess={resource.loadPage} />
            <Routes>
                <Route exact path={props.basePath + "/create"} element={<Layout>props.components?.form</Layout>} />
                <Route exact path={props.basePath + "/:id/edit"} element={
                    <Layout>
                        {({match}) => {
                            const Form = props.components?.form

                            return Form ? <Form id={match.params.id} resource={resource}/> : null
                        }}
                    </Layout>
                } />
                <Route exact path={props.basePath + "/:id"} element={({match}) => {
                    const Item = props.components?.item;
                    initResourceItem(resource, match.params.id)

                    return Item ? <Item resource={resource} id={match.params.id} /> : null
                }} />
                <Route exact path={props.basePath} element={
                    <Layout>
                        {List ? <List resource={resource} /> : null}
                    </Layout>
                } />
            </Routes>
        </>
    );
}

Crud.propTypes = {
    basePath: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    layout: PropTypes.any.isRequired,
    components: PropTypes.shape({
        list: PropTypes.any,
        item: PropTypes.any,
        form: PropTypes.any,
        deleteModal: PropTypes.any,
    }),
}

export default Crud;
