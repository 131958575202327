import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_WALLET_STATUS_COUNT_SUCCESS,
  GET_WALLET_STATUS_COUNT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  wallets: [],
  walletDetail: {},
  error: {},
  walletsStatusCount: {}
}

const wallets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        wallets: action.payload.items,
      }

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        wallets: [...state.wallets, action.payload],
      }

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        walletDetail: action.payload,
      }

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        wallets: state.wallets.map(wallet =>
          wallet.id.toString() === action.payload.id.toString()
            ? { wallet, ...action.payload }
            : wallet
        ),
      }

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        wallets: state.wallets.filter(
          wallet => wallet.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_WALLET_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        walletsStatusCount: { ...state.walletsStatusCount, [action.status]: action.payload }
      }

    case GET_WALLET_STATUS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default wallets
