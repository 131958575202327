import React, { useEffect, useState, Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  Label,
  Input,
  Table
} from "reactstrap"
import { useParams } from "react-router-dom"

//i18n
import { useTranslation } from "react-i18next"

const Project = props => {
    const { t } = useTranslation()
    const [currentProject, setCurrentProject] = useState({
        name: "Horizon 2025 BTC-ETH",
        description: "",
        risk: "balanced",
        investmentDuration: "24 - 36 mois",
        amountInitialPayments: "15 000",
        amountProgrammedPayments: "250",
        paymentFrequency: "monthly",
    });

    const [currentInvestor, setCurrentInvestor] = useState({
        name: "Price",
        firstname: "Cynthia",
        phone: "06 00 65 25 67",
        email: "cynthiaskote@gmail.com",
        address: "9 rue JJ ROUSSEAU",
        zipcode: "21000",
        city: "DIJON",
        comment: "Mlle Price est avocate en région parisienne",
    });

    const [currentFiles, setCurrentFiles] = useState({
        file1: "Rapport d'audit.pdf",
        file2: "CNI"
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                <title>{t('Project')} | Hesiode</title>
                </MetaTags>
                <Container fluid>
                    <h4 className="mb-3">{t('Project')}{" : "}{currentInvestor.firstname}{" "}{currentInvestor.name}{" - "}{currentProject.name}</h4>
                    <Row>
                        <Col xl="8">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="p-2 flex-shrink-1">
                                            <div className="h4 card-title mb-3">{t('Project Summary')} <i className="mdi mdi-pencil text-success"></i></div>
                                            <div>
                                                {t('Project Summary Presentation')}
                                            </div>
                                            <div className="table-responsive mt-3">
                                                <table className="table table-wrap table">
                                                    <tbody>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Project Name')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.name}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.riskProfil')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.risk}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.investmentDuration')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.investmentDuration}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.amountInitialPayments')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.amountInitialPayments}{"€"}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div className="fw-bold my-1">{t('Wallet.amountProgrammedPayments')} {" :"}</div></td>
                                                            <td><div className="my-1 mx-5">{currentProject.amountProgrammedPayments}{"€/"}{currentProject.paymentFrequency}</div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="p-2 w-100">
                                            <div className="h4 card-title mb-3">{t('Project Progress')}</div>
                                            <ul className="verti-timeline list-unstyled">
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="me-3 mt-n2">
                                                            <i className="mdi mdi-account-check fs-2 text-primary"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <p className="d-inline rounded-pill bg-danger text-white font-size-10">{t('Account not verified')}</p>
                                                                <button  type="button" className="btn btn-primary mt-2 ">{t('send the confirmation email')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="me-3 mt-n2">
                                                            <i className="mdi mdi-security fs-2 text-primary"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <p className="d-inline rounded-pill bg-danger text-white font-size-10">{t('Kyc not realized')}</p>
                                                                <p className="mt-2">{t('Kyc not realized Message')}</p>
                                                                <button  type="button" className="btn btn-primary">{t('Kyc not realized button')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="me-3 mt-n1">
                                                            <i className="bx bx-money fs-2 text-primary"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <p className="d-inline rounded-pill bg-danger text-white font-size-10">{t('IBAN')}</p>
                                                                <p className="mt-2">{t('IBAN Message')}</p>
                                                                <button  type="button" className="btn btn-primary">{t('IBAN Button')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="me-3 mt-n2">
                                                            <i className="mdi mdi-chart-pie fs-2 text-primary"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <p className="d-inline rounded-pill bg-danger text-white font-size-10">{t('Unrealized Allocation')}</p>
                                                                <p className="mt-2">{t('Unrealized Allocation Message')}</p>
                                                                <button  type="button" className="btn btn-primary">{t('Unrealized Allocation Button')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-around">
                                        <div className="form-check form-switch form-switch-md mb-3">
                                            <input type="checkbox" className="form-check-input" id="customSwitchsizemd" />
                                            <label className="form-check-label">{t('Project Customer allocation')}</label>
                                        </div>
                                        <button  type="button" className="btn btn-primary">{t('Unrealized Allocation Button')}</button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <button  type="button" className="btn btn-dark">
                                        <i className="mdi mdi-arrow-left-drop-circle me-2"></i>
                                        {t('Modify Project')}
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardBody>
                                    <div className="h4 card-title mb-3">{t('Investor File')} <i className="mdi mdi-pencil text-success"></i></div>
                                    <p>{currentInvestor.comment}</p>
                                    <div className="table-responsive mb-5">
                                        <table className="table table-nowrap table">
                                            <tbody>
                                                <tr>
                                                    <td><div className="fw-bold my-1">{t('Investor')} {" :"}</div></td>
                                                    <td><div className="my-1 mx-5">{currentInvestor.firstname}{" "}{currentInvestor.name}</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="fw-bold my-1">{t('Customer.phone')} {" :"}</div></td>
                                                    <td><div className="my-1 mx-5">{currentInvestor.phone}</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="fw-bold my-1">{t('Customer.mail')} {" :"}</div></td>
                                                    <td><div className="my-1 mx-5">{currentInvestor.email}</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="fw-bold my-1">{t('Customer.address')} {" :"}</div></td>
                                                    <td><div className="my-1 mx-5">{currentInvestor.address}{", "}{currentInvestor.zipcode}{" "}{currentInvestor.city}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="h4 card-title mb-3">{t('Documents')}</div>
                                        <button  type="button" className="btn btn-primary">{t("Add")}</button>
                                    </div>
                                    <table className="table table-nowrap table">
                                        <tbody>
                                            <tr className="d-flex justify-content-between">
                                                <td><div className="fw-bold my-1"><i className="fa fa-solid fa-file-pdf me-2 text-danger"></i>{currentFiles.file1}</div></td>
                                                <td><div className="my-1 mx-5"><a>...</a></div></td>
                                            </tr>
                                            <tr className="d-flex justify-content-between">
                                                <td><div className="fw-bold my-1"><i className="fa fa-solid fa-file-pdf me-2 text-danger"></i>{currentFiles.file2}</div></td>
                                                <td><div className="my-1 mx-5"><a>...</a></div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="mt-5">
                                        <div className="h4 card-title mb-3">{t('Events')}</div>
                                        <ul className="verti-timeline list-unstyled">
                                            <li className="event-list">
                                                <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <h5 className="font-size-14">22 Nov <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                                        </h5>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>Responded to need “Volunteer Activities</div>
                                                    </div>
                                                </div>
                                            </li>
                                             <li className="event-list">
                                                <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <h5 className="font-size-14">17 Nov <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                                        </h5>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>Everyone realizes why a new common language would be desirable...</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default Project;