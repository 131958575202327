import { call, put, takeEvery, select } from "redux-saga/effects"

// Crypto Redux States
import { GET_PROJECTS, GET_PROJECT_DETAIL, ADD_NEW_PROJECT, DELETE_PROJECT, UPDATE_PROJECT, GET_WALLET_STATUS_COUNT } from "./actionTypes"
import {
  getWalletsSuccess,
  getWalletsFail,
  getWalletDetailSuccess,
  getWalletDetailFail,
  addWalletFail,
  addWalletSuccess,
  updateWalletSuccess,
  updateWalletFail,
  deleteWalletSuccess,
  deleteWalletFail,
  getWalletsStatusCountSuccess,
  getWalletsStatusCountFail
} from "./actions"

//Include Both Helper File with needed methods
import { getWallets as fakeGetWaller, getWalletsDetails, addNewWallet, updateWallet, deleteWallet } from "helpers/fakebackend_helper"
import {
  getWallets
} from "helpers/fire_api_helper"
import { selectAccessToken } from "store/auth/login/selector"

function* fetchWallets({ filter }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(getWallets, accessToken, filter)
    yield put(getWalletsSuccess(response))
  } catch (error) {
    yield put(getWalletsFail(error))
  }
}

function* fetchWalletDetail({ walletId }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(getWalletsDetails, walletId)
    yield put(getWalletDetailSuccess(response))
  } catch (error) {
    yield put(getWalletDetailFail(error))
  }
}

function* onUpdateWallet({ payload: wallet }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(updateWallet, wallet)
    yield put(updateWalletSuccess(response))
  } catch (error) {
    yield put(updateWalletFail(error))
  }
}

function* onDeleteWallet({ payload: wallet }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(deleteWallet, wallet)
    yield put(deleteWalletSuccess(response))
  } catch (error) {
    yield put(deleteWalletFail(error))
  }
}

function* onAddNewProject({ payload: wallet }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(addNewWallet, wallet)
    yield put(addWalletSuccess(response))
  } catch (error) {

    yield put(addWalletFail(error))
  }
}

function* onAddNewWallet({ payload: wallet }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(addNewWallet, wallet)
    yield put(addWalletSuccess(response))
  } catch (error) {

    yield put(addWalletFail(error))
  }
}

function* onGetWalletsCount({ payload }) {
  try {
    const response = yield call(getWallets, payload)
    yield put(getWalletsStatusCountSuccess(response.length, payload.status))
  } catch (error) {
    yield put(getWalletsStatusCountFail(error))
  }
}

function* walletsSaga() {
  yield takeEvery(GET_PROJECTS, fetchWallets)
  yield takeEvery(GET_PROJECT_DETAIL, fetchWalletDetail)
  yield takeEvery(ADD_NEW_PROJECT, onAddNewWallet)
  yield takeEvery(UPDATE_PROJECT, onUpdateWallet)
  yield takeEvery(DELETE_PROJECT, onDeleteWallet)
  yield takeEvery(GET_WALLET_STATUS_COUNT, onGetWalletsCount)
}

export default walletsSaga
