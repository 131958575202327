import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { ACTIVATE_USER } from "./actionTypes"
import { activateUserSuccessful, activateUserFailed } from "./actions"

import {POST_ACTIVATE} from "../../../helpers/fire_url_helpers"
import {loginSuccess} from "../login/actions";

// Is user activate successful then direct plot user in redux.
function* activateUser({ payload: { token, user, password, navigate } }) {
  try {
    const response = yield call(fetch, POST_ACTIVATE(user), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, password}),
      })

      if (!response.ok) {
        throw new Error('Failed to create account')
      }

      const body = yield response.json();

      yield put(activateUserSuccessful())
      yield put(loginSuccess({token: body.token, navigate}))
  } catch (error) {
    yield put(activateUserFailed(error.toString()))
  }
}

export function* watchUserActivate() {
  yield takeEvery(ACTIVATE_USER, activateUser)
}

function* activateSaga() {
  yield all([fork(watchUserActivate)])
}

export default activateSaga
