import React, { useEffect, useState, Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap"
import { Link } from "react-router-dom";

import { getWallets as onGetWallets } from "store/actions";

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const Outstandings = props => {
  const dispatch = useDispatch();

  const reports = [
    { title: props.t("Dashboard.nbCustomer"), iconClass: "bxs-user", description: "10" },
    { title: props.t("Dashboard.nbCurrentWallets"), iconClass: "bxs-folder", description: "14" },
    { title: props.t("Dashboard.myBalance"), iconClass: "bxs-wallet", description: "635000€" },
  ]

  const { wallets } = useSelector((state) => ({
    wallets: state.wallets.wallets,
  }))

  useEffect(() => {
    dispatch(onGetWallets());
  }, [dispatch]);

  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: wallets.length, // replace later with size(wallets),
    custom: true,
  }

  const ProjetColumns = [
    {
      dataField: "name",
      text: props.t("Customer"),
      sort: true,
      formatter: function formatter(cellContent, row) {
        // return <Link to={`/customer/${row.id}`}>{cellContent}</Link>
        return <Link to="/customer/1">{cellContent}</Link>
      }
    },
    {
      dataField: "riskProfil",
      text: props.t("Wallet.riskProfilAcronym"),
      sort: true,
      formatter: function formatter(cellContent, row) {
        
        switch (cellContent) {
          case 'careful': return <p className="text-success">{props.t("Wallet." + cellContent)}</p>;break;
          case 'balanced': return <p className="text-warning">{props.t("Wallet." + cellContent)}</p>;break;
          case 'dynamic': return <p className="text-danger">{props.t("Wallet." + cellContent)}</p>;break;
        }
      }
    },
    {
      dataField: "walletName",
      text: props.t("WalletsAcronym"),
      sort: true
    },
    {
      dataField: "balance",
      text: props.t("Outstanding"),
      sort: true
    },
    {
      dataField: "wallet24H",
      text: props.t("Wallet.wallet24HAcronym"),
      sort: true,
      formatter: function formatter(cellContent, row) {
        if (cellContent === undefined) {
          return "X"
        }
        if (cellContent.charAt(0) !== "-") {
          return <div className="text-success">{cellContent}{"%"}<li className="bx bx-up-arrow-alt"></li></div>
        } else {
          return <div className="text-danger">{cellContent}{"%"}<li className="bx bx-down-arrow-alt"></li></div>
        }
      }
    },
    {
      dataField: "creationDate",
      text: props.t("Wallet.creationDate"),
      sort: true
    },
    {
      dataField: "state",
      text: props.t("State"),
      sort: true,
      formatter: function formatter(cellContent, row) {
        switch (cellContent) {
          case 'Draft': return <p className="d-inline rounded-pill bg-dark text-white">{props.t("State." + cellContent)}</p>;break;
          case 'In signature': return <p className=" d-inline rounded-pill bg-primary text-white">{props.t("State." + cellContent)}</p>;break;
          case 'In validation': return <p className="d-inline rounded-pill bg-warning text-white">{props.t("State." + cellContent)}</p>;break;
          case 'Closed': return <p className="d-inline rounded-pill bg-secondary text-white">{props.t("State." + cellContent)}</p>;break;
          case 'In anomaly': return <p className="d-inline rounded-pill bg-danger text-white">{props.t("State." + cellContent)}</p>;break;
          case 'In progress': return <p className="d-inline rounded-pill bg-success text-white">{props.t("State." + cellContent)}</p>;break;
        }
      }
    },
    {
      dataField: "upfrontPay",
      text: props.t("UpfrontPay"),
      sort: true
    },
    {
      dataField: "scheduledPay",
      text: props.t("ScheduledPay"),
      sort: true,
      formatter: function formatter(cellContent, row){
        if (row.payment_recurrence === "Month") return (cellContent + "/" +(props.t("Month")));
        else return (cellContent + "/" +(props.t("Week")));
      }
    },
    {
      dataField: "Details",
      isDummyField: true,
      text: props.t("Details"),
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
        >
          {props.t("Details")}
        </Button>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t('My Wallets')} | Hesiode</title>
        </MetaTags>
        <Container fluid>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <h4>{props.t('My Wallets')}</h4>
            <div>
              <Link to="/projects/new" className="btn btn-primary">
                <i className="bx bx-user-plus mx-1"></i> {props.t('Project.addNewProject')}
              </Link>
            </div>
          </div>

          <Row>
            {/* Reports Render */}
            {
              reports.map((report, key) => (
                <Col key={"_col_" + key}>
                  <Card>
                    <CardBody>
                    <div className="mini-stats-wid">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                        </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            }
          </Row>

          <Row>
            <Col md="12" xl="12" xxl="12">
              <Card>
                <CardBody>
                    <div className="h4 card-title">{props.t('My Wallets')}</div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <div className="search-box mx-4 mt-4 d-inline-block">
                          <div className="position-relative">
                            <label className="search-label">
                              <span id="search-bar-0-label" className="sr-only">Search this table</span>
                              <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder={props.t('Search')} defaultValue="" />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                        <div>
                          <a className="btn btn-rounded btn-success mx-4" href="/projects/new">
                            <i className="bx bx-download mx-1"></i>{props.t('Export')}</a>
                        </div>
                      </div>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProjetColumns}
                    data={wallets}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={wallets}
                        columns={ProjetColumns}
                        bootstrap4
                        search
                      >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive mx-4">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pagination pagination-rounded justify-content-end">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Outstandings.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Outstandings);
