const wallets = [
  {
    id: 1,
    name: "Cynthia Price",
    balance: "$3,325.04",
    creationDate: "07/10/2022",
    upfrontPay: "50,000$",
    scheduledPay: "1000$",
    mouthly: true,
    state: "In signature",
    wallet24H: "-3",
    riskProfil: "careful",
    walletName: "Sécurité"
  },
  {
    id: 2,
    name: "Paul Dupuit",
    balance: "$2,517.79",
    creationDate: "06/11/2022",
    upfrontPay: "80,000$",
    scheduledPay: "2000$",
    mouthly: true,
    state: "In signature",
    wallet24H: "5",
    riskProfil: "balanced",
    walletName: "pour mes petits-enfants"
  },
  {
    id: 3,
    name: "Lacy Bond",
    balance: "$2,352.59",
    creationDate: "05/12/2022",
    upfrontPay: "15,000$",
    scheduledPay: "350$",
    mouthly: false,
    state: "In signature",
    wallet24H: "2",
    riskProfil: "dynamic",
    walletName: "Futur voyage en Alaska"
  },
  {
    id: 4,
    name: "Mcleod Hansen",
    balance: "$1,779.39",
    creationDate: "15/10/2021",
    upfrontPay: "10,000$",
    scheduledPay: "200$",
    mouthly: false,
    state: "In validation",
    wallet24H: "-10",
    riskProfil: "dynamic",
    walletName: "Epargne"
  },
  {
    id: 5,
    name: "Tonia Fletcher",
    balance: "$3,047.31",
    creationDate: "01/01/2022",
    upfrontPay: "45,000$",
    scheduledPay: "1000$",
    mouthly: true,
    state: "Closed",
    wallet24H: "5",
    riskProfil: "careful",
    walletName: "Epargne pour les enfants"
  },
  {
    id: 6,
    name: "Valdez Stevens",
    balance: "$1,391.35",
    creationDate: "10/10/2021",
    upfrontPay: "25,000$",
    scheduledPay: "1500$",
    mouthly: true,
    state: "In signature",
    wallet24H: "3",
    riskProfil: "balanced",
    walletName: "Sécurité futur"
  },
  {
    id: 7,
    name: "Fanny Harding",
    balance: "$2,704.77",
    creationDate: "05/08/2022",
    upfrontPay: "30,000$",
    scheduledPay: "100$",
    mouthly: true,
    state: "In anomaly",
    wallet24H: "-3",
    riskProfil: "careful",
    walletName: "Epargne"
  },
  {
    id: 8,
    name: "Cynthia Price",
    balance: "$3,993.51",
    creationDate: "06/10/2022",
    upfrontPay: "50,000$",
    scheduledPay: "1000$",
    mouthly: false,
    state: "Draft",
    wallet24H: "-2",
    riskProfil: "dynamic",
    walletName: "Futur projet"
  },
  {
    id: 9,
    name: "Paul Dupuit",
    balance: "$1,120.64",
    creationDate: "05/11/2021",
    upfrontPay: "75,000$",
    scheduledPay: "800$",
    mouthly: false,
    state: "In progress",
    wallet24H: "15",
    riskProfil: "dynamic",
    walletName: "futur achat de l'europe"
  },
  {
    id: 10,
    name: "Black Greer",
    balance: "$2,735.25",
    creationDate: "06/12/2022",
    upfrontPay: "100,000$",
    scheduledPay: "1000$",
    mouthly: true,
    state: "Closed",
    wallet24H: "1",
    riskProfil: "careful",
    walletName: "Epargne Greer"
  },
  {
    id: 11,
    name: "Rich Cook",
    balance: "$2,275.84",
    creationDate: "07/10/2020",
    upfrontPay: "80,000$",
    scheduledPay: "2000$",
    mouthly: true,
    state: "In validation",
    wallet24H: "-7",
    riskProfil: "balanced",
    walletName: "pour les parents"
  },
  {
    id: 12,
    name: "Collier Chase",
    balance: "$3,396.98",
    creationDate: "05/12/2020",
    upfrontPay: "100,000$",
    scheduledPay: "50$",
    mouthly: false,
    state: "Draft",
    wallet24H: "4",
    riskProfil: "dynamic",
    walletName: "epargne"
  },
  {
    id: 13,
    name: "Bridget Acosta",
    balance: "$3,136.88",
    creationDate: "10/11/2020",
    upfrontPay: "10,000$",
    scheduledPay: "1500$",
    mouthly: false,
    state: "Draft",
    wallet24H: "2",
    riskProfil: "careful",
    walletName: "Sécurité"
  },
  {
    id: 14,
    name: "Lindsay Robinson",
    balance: "$1,390.25",
    creationDate: "14/12/2020",
    upfrontPay: "25,000$",
    scheduledPay: "1000$",
    mouthly: true,
    state: "In anomaly",
    wallet24H: "-17",
    riskProfil: "dynamic",
    walletName: "Epargne Robinson"
  },
  {
    id: 15,
    name: "Imogene Moore",
    balance: "$1,005.70",
    creationDate: "15/06/2020",
    upfrontPay: "35,000$",
    scheduledPay: "1000$",
    mouthly: false,
    state: "In signature",
    wallet24H: "-2",
    riskProfil: "dynamic",
    walletName: "Pour mes enfants"
  },
  {
    id: 16,
    name: "Chen Dalton",
    balance: "$1,404.64",
    creationDate: "08/03/2020",
    upfrontPay: "75,000$",
    scheduledPay: "3000$",
    mouthly: true,
    state: "Closed",
    wallet24H: "8",
    riskProfil: "balanced",
    walletName: "To the moon 2025"
  },
  {
    id: 17,
    name: "Nguyen Gay",
    balance: "$3,757.22",
    creationDate: "17/06/2020",
    upfrontPay: "140,000$",
    scheduledPay: "50$",
    mouthly: false,
    state: "In signature",
    wallet24H: "5",
    riskProfil: "careful",
    walletName: "Epargne jojo"
  }
]

const states = [
  "Draft",
  "In validation", // (while waiting for the CGP to sign it, for example when the client has moved the proposed allocation)
  "In signature", // (when the sepa mandate and the management mandate have been generated
  "In progress",
  "Closed", // (when there is no more money on the wallet)
  "Archived", // (no longer displayed on the user/CGP side)
  "In anomaly", // (e.g.: Asset freeze procedure in the context of the fight against money laundering/terrorism financing)
]

const options = {
  chart: {
    height: 290,
    type: "bar",
    toolbar: {
      show: !1,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "14%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: !1,
  },
  series: [
    {
      name: "Overview",
      data: [42, 56, 40, 64, 26, 42, 56, 35, 62],
    },
  ],
  grid: {
    yaxis: {
      lines: {
        show: !1,
      },
    },
  },
  yaxis: {
    title: {
      text: "% (Percentage)",
    },
  },
  xaxis: {
    labels: {
      rotate: -90,
    },
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    title: {
      text: "Week",
    },
  },
  colors: ["#556ee6"],
}

const series = [
  {
    name: "Overview",
    data: [42, 56, 40, 64, 26, 42, 56, 35, 62],
  },
]

const users = [
  {
    "firstName": "Marie",
    "surname": "{{surname()}",
    "age": 21,
    "gender": "female",
    "email": "marieatkinson@gadtron.com",
    "phone": "+1 (922) 467-3864",
    "address": "655 Elton Street, Rote, Wisconsin, 3179",
    "registered": "2021-11-28T05:15:10 -01:00",
    "favoriteFruit": "apple"
  },
  {
    "firstName": "Fay",
    "surname": "{{surname()}",
    "age": 23,
    "gender": "female",
    "email": "fayatkinson@gadtron.com",
    "phone": "+1 (900) 538-3891",
    "address": "774 Schenck Street, Canoochee, New Hampshire, 7503",
    "registered": "2016-04-02T04:45:33 -02:00",
    "favoriteFruit": "strawberry"
  },
  {
    "firstName": "Carroll",
    "surname": "{{surname()}",
    "age": 40,
    "gender": "male",
    "email": "carrollatkinson@gadtron.com",
    "phone": "+1 (923) 522-3444",
    "address": "587 Pulaski Street, Greenfields, Alaska, 4915",
    "registered": "2015-01-01T09:34:51 -01:00",
    "favoriteFruit": "apple"
  },
  {
    "firstName": "Riddle",
    "surname": "{{surname()}",
    "age": 33,
    "gender": "male",
    "email": "riddleatkinson@gadtron.com",
    "phone": "+1 (833) 550-3006",
    "address": "688 Fay Court, Clinton, Northern Mariana Islands, 6336",
    "registered": "2022-09-02T04:19:40 -02:00",
    "favoriteFruit": "banana"
  },
  {
    "firstName": "Hartman",
    "surname": "{{surname()}",
    "age": 25,
    "gender": "male",
    "email": "hartmanatkinson@gadtron.com",
    "phone": "+1 (938) 534-2276",
    "address": "358 Newkirk Placez, Riegelwood, Oregon, 3074",
    "registered": "2017-08-24T08:32:07 -02:00",
    "favoriteFruit": "apple"
  },
  {
    "firstName": "Sofia",
    "surname": "{{surname()}",
    "age": 32,
    "gender": "female",
    "email": "sofiaatkinson@gadtron.com",
    "phone": "+1 (920) 512-3591",
    "address": "946 Colby Court, Bodega, Marshall Islands, 5173",
    "registered": "2016-03-03T04:13:22 -01:00",
    "favoriteFruit": "strawberry"
  },
  {
    "firstName": "Dillon",
    "surname": "{{surname()}",
    "age": 26,
    "gender": "male",
    "email": "dillonatkinson@gadtron.com",
    "phone": "+1 (913) 582-3294",
    "address": "402 Montieth Street, Saticoy, Arizona, 1898",
    "registered": "2015-12-08T07:12:40 -01:00",
    "favoriteFruit": "apple"
  }
]

export { wallets, options, series, states, users }
