import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import { POST_JWT_REGISTER } from "helpers/url_helper"
import { postApiRegister } from "helpers/fire_api_helper"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    if (process?.env?.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, POST_JWT_REGISTER, user)
      yield put(registerUserSuccessful(response))
    } else if (process?.env?.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))
    } else if (process?.env?.REACT_APP_DEFAULTAUTH === "api") {
      const newUser = {
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        password: user.password,
        roles: [
          "ROLE_WMA"
        ],
        birthdate: user.birthdate  
      }
      const response = yield call(postApiRegister, newUser)
      
      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
